import { LEARN_MORE_PRICES_LINK } from 'src/constants/externalLinks';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

interface UpgradeLearnMoreButtonsProps {
  dataGTM: string;
}

export const UpgradeLearnMoreButtons = ({
  dataGTM,
}: UpgradeLearnMoreButtonsProps) => {
  const navigate = useNavigate();

  return (
    <div className="nj-tier-auth-footer-button-wrapper">
      <a
        target="_blank"
        rel="noreferrer"
        href={LEARN_MORE_PRICES_LINK}
        className="nj-tier-auth-footer-button learn-more-button"
      >
        Learn more
      </a>
      <button
        id={dataGTM}
        type="button"
        className="nj-tier-auth-footer-button signup-button"
        onClick={() => navigate(AppRoutes.PAYMENT)}
      >
        Upgrade
      </button>
    </div>
  );
};

import { useMemo } from 'react';
import { useGetConversationByIdQuery } from 'src/store/services';
import { useAppSelector, sessionState } from 'src/store';
import { useConversationParams } from './useConversationParams';
/**
 * This hook should be used only for base information about the current conversation.
 * For messages and tasks use separate hooks!
 */
export const useCurrentConversation = () => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const { currentConversationId, isLandingPage } = useConversationParams();

  const shouldSkipGetConversationById = isLandingPage || !user_id;

  const {
    data: conversationData,
    isLoading: isConversationLoading,
    isFetching: isConversationFetching,
    isError: isConversationError,
    error: conversationError,
    isSuccess: isConversationSuccess,
  } = useGetConversationByIdQuery(
    {
      user_id,
      conversation_id: currentConversationId,
    },
    {
      skip: shouldSkipGetConversationById,
    },
  );

  const currentConversation = useMemo(
    () => (!isLandingPage ? conversationData : undefined),
    [conversationData, isLandingPage],
  );

  return useMemo(
    () => ({
      currentConversation,
      conversationMessages: conversationData?.messages || [],
      isCurrentConversationLoading:
        isConversationLoading || isConversationFetching,
      isConversationSuccess,
      isConversationError,
      conversationError,
    }),
    [
      currentConversation,
      conversationData?.messages,
      isConversationLoading,
      isConversationFetching,
      isConversationSuccess,
      isConversationError,
      conversationError,
    ],
  );
};

import dayjs from 'dayjs';
import { WelcomeUpsellModal } from 'src/components/WelcomeUpsellModal';
import { useSession, useRecurringUpsellModal } from 'src/hooks';
import { useAppSelector, sessionState } from 'src/store';
import { getUpsellModalType } from 'src/utils';
import { REDIRECT_TO_STRIPE_TIMESTAMP } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

export const RecurringUpsellModal = () => {
  const navigate = useNavigate();

  const { appUser, isOpenTier } = useSession();
  const { setNewUpsellTimestamp } = useRecurringUpsellModal();
  const {
    recurringUpsellModalControls: { isVisible },
  } = useAppSelector(sessionState);

  const modalType = getUpsellModalType(appUser.tier_id);

  const handleCloseModal = () => {
    setNewUpsellTimestamp();
  };

  const handleContinueClick = () => {
    if (!isOpenTier) {
      return;
    }

    navigate(AppRoutes.SIGN_UP);
  };

  const handleUpgradeClick = () => {
    if (!isOpenTier) {
      return;
    }

    handleCloseModal();

    handleContinueClick();

    localStorage.setItem(
      REDIRECT_TO_STRIPE_TIMESTAMP,
      dayjs().valueOf().toString(),
    );
  };

  if (!modalType) {
    return null;
  }

  return (
    <WelcomeUpsellModal
      modalType={modalType}
      isOpen={isVisible}
      onClose={handleCloseModal}
      onContinue={handleContinueClick}
      onUpgrade={isOpenTier ? handleUpgradeClick : undefined}
    />
  );
};

import { PaymentPlanOption, PaymentPlanOptionWithSale } from 'src/types';
import {
  getCalculatedSale,
  getNewAmountWithDiscount,
} from 'src/utils/payments';

export const BASE_PRICE_PER_MONTH = 10;
export const FREE_CREDITS = 10;
export const PROMO_COUPON_AMOUNT = 5;
export const PROMO_COUPON = 'NINJA5';

export const PRICE_PER_MONTH = BASE_PRICE_PER_MONTH - PROMO_COUPON_AMOUNT;

// TODO(olha): use BE data, remove hardcode
export const PLAN_OPTIONS: PaymentPlanOptionWithSale[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    isDefault: true,
    newAmount: getNewAmountWithDiscount(1000, PROMO_COUPON_AMOUNT),
    promotion_code: PROMO_COUPON,
    sale: getCalculatedSale(1000, PROMO_COUPON_AMOUNT),
    couponAmount: PROMO_COUPON_AMOUNT,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
    newAmount: getNewAmountWithDiscount(2000, PROMO_COUPON_AMOUNT),
    promotion_code: PROMO_COUPON,
    sale: getCalculatedSale(2000, PROMO_COUPON_AMOUNT),
    couponAmount: PROMO_COUPON_AMOUNT,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
    newAmount: getNewAmountWithDiscount(3000, PROMO_COUPON_AMOUNT),
    promotion_code: PROMO_COUPON,
    sale: getCalculatedSale(3000, PROMO_COUPON_AMOUNT),
    couponAmount: PROMO_COUPON_AMOUNT,
  },
];

export const PLAN_OPTIONS_FOR_FREE: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    isDefault: true,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
  },
];

export const RENEW_EARLY_MONTHLY_OPTIONS: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_monthly_10',
    amount: 1000,
    tasksCount: 600,
  },
  {
    lookup_key: 'pro_monthly_20',
    amount: 2000,
    tasksCount: 1200,
  },
  {
    lookup_key: 'pro_monthly_30',
    amount: 3000,
    tasksCount: 1800,
  },
];

export const RENEW_EARLY_ANNUALLY_OPTIONS: PaymentPlanOption[] = [
  {
    lookup_key: 'pro_annually_84',
    amount: 8400,
    tasksCount: 24000,
    isAnnual: true,
  },
  {
    lookup_key: 'pro_annually_168',
    amount: 16800,
    tasksCount: 48000,
    isAnnual: true,
  },
  {
    lookup_key: 'pro_annually_252',
    amount: 25200,
    tasksCount: 72000,
    isAnnual: true,
  },
];

export const PLAN_FREE_FEATURES = [
  { title: 'Advisor', withBorder: false },
  { title: 'Researcher', withBorder: false },
  { title: 'Coder', withBorder: false },
  { title: 'Deep Researcher', withBorder: false },
  { title: 'Image Generator', withBorder: false },
  { title: 'Scheduler', withBorder: true },
  { title: '5 minute video chat sessions', withBorder: false },
  { title: '10 custom conversation tones', withBorder: false },
  { title: 'Email & In-product support', withBorder: false },
];

// TODO(olha): needs clearing and refactoring
export const PRO_MAIN_FEATURES = [
  { title: 'Researcher' },
  { title: 'Image Generator & Editor' },
  { title: 'Coder' },
  { title: 'Writing Advisor' },
  { title: 'Meeting Scheduler' },
];

export const PRO_ADDITIONAL_FEATURES = [
  { title: '30 minute avatar video sessions' },
  { title: '10 custom conversation tones' },
  { title: 'Email & In-product support' },
];

export const PLAN_PRO_FEATURES = [
  { title: 'Advisor', withBorder: false },
  { title: 'Researcher', withBorder: false },
  { title: 'Coder', withBorder: false },
  { title: 'Deep Researcher', withBorder: false },
  { title: 'Image Generator', withBorder: false },
  { title: 'Image Editor', withBorder: false },
  { title: 'Multi-party Scheduler', withBorder: false },
  {
    title: '24+ External models',
    withBorder: true,
    isExternalModels: true,
  },
  {
    title: 'State of art interactive avatars',
    withBorder: false,
    // link: { label: '20 Avatars', href: PAY_PER_USE },
  },
  { title: '10 custom conversation tones', withBorder: false },
  { title: 'Email & In-product support', withBorder: false },
];

export const PAYMENTS_TIME_FORMAT = 'h:mm A';
export const PAYMENTS_DATE_FORMAT = 'MMM D, YYYY';
export const PAYMENTS_DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

export const DEFAULT_UPSELL_DELAY = 4 * 60 * 60 * 1000; // 4h

export const REDIRECT_TO_STRIPE_EXP_TIME = 30 * 60 * 1000; // 30m

import {
  EXTERNAL_MODELS_IMAGE_DARK,
  EXTERNAL_MODELS_IMAGE_LIGHT,
  EXTERNAL_MODELS_LINK,
} from 'src/constants/externalLinks';
import { useColorTheme } from 'src/hooks';

export const ExternalModelsList = () => {
  const { isDarkTheme } = useColorTheme();

  return (
    <>
      <a
        href={EXTERNAL_MODELS_LINK}
        target="_blank"
        rel="noreferrer"
        className="nj-plan-section--llm-button"
      >
        <img
          className="nj-plan-section--llm-image"
          alt="ninja-external-models"
          src={
            isDarkTheme
              ? EXTERNAL_MODELS_IMAGE_DARK
              : EXTERNAL_MODELS_IMAGE_LIGHT
          }
        />
      </a>

      {/* TODO(olha): will be finished later */}
      {/* {isVisible && (
        <div ref={containerRef} className="nj-plan-section--llm-tooltip">
          <div>
            <span className="nj-plan-section--llm-tooltip-title">
              Amazon Bedrock
            </span>

            <ul className="nj-plan-section--llm-tooltip-list">
              {Object.values(externalModelsSet)
                .filter(
                  (item) =>
                    item.provider === ExternalModelProvider.AMAZON_BEDROCK,
                )
                .map((item) => (
                  <li
                    key={item.display_name}
                    className="nj-plan-section--llm-tooltip-list-item"
                  >
                    {item.display_name}
                  </li>
                ))}
            </ul>
          </div>

          <div>
            <div>
              <span className="nj-plan-section--llm-tooltip-title">OpenAI</span>

              <ul className="nj-plan-section--llm-tooltip-list">
                {Object.values(externalModelsSet)
                  .filter(
                    (item) => item.provider === ExternalModelProvider.OPENAI,
                  )
                  .map((item) => (
                    <li
                      key={item.display_name}
                      className="nj-plan-section--llm-tooltip-list-item"
                    >
                      {item.display_name}
                    </li>
                  ))}
              </ul>
            </div>

            <div>
              <span className="nj-plan-section--llm-tooltip-title">Google</span>

              <ul className="nj-plan-section--llm-tooltip-list">
                {Object.values(externalModelsSet)
                  .filter(
                    (item) => item.provider === ExternalModelProvider.GOOGLE,
                  )
                  .map((item) => (
                    <li
                      key={item.display_name}
                      className="nj-plan-section--llm-tooltip-list-item"
                    >
                      {item.display_name}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

import { AppRoutes, GTMEvent } from 'src/types';
import { sendGTMEvent } from 'src/utils';
import { useNavigate } from 'react-router-dom';

type UpgradeButtonProps = {
  onClick?: () => void;
  title?: string;
  dataGTM: string;
};

export const UpgradeButton = ({
  onClick,
  title = 'Upgrade',
  dataGTM,
}: UpgradeButtonProps) => {
  const navigate = useNavigate();

  const handleUpgradeClick = () => {
    navigate(AppRoutes.PAYMENT);
    if (onClick) {
      onClick();
    }
    sendGTMEvent(GTMEvent.UPGRADE_BUTTON_CLICKS);
  };

  return (
    <button
      id={dataGTM}
      className="nj-upgrade-button"
      onClick={handleUpgradeClick}
    >
      {title}
    </button>
  );
};

import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

export const ContactNinjaButton = () => {
  const { onToggleVisibleContactNinjaModal } = useContext(SessionContext);

  const handleContactNinjaClick = () => {
    onToggleVisibleContactNinjaModal(true);
  };

  return (
    <button className="nj-upgrade-button" onClick={handleContactNinjaClick}>
      Contact Ninja
    </button>
  );
};

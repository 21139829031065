import styles from './UpsellMessageBlock.module.scss';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';
import { useSession } from 'src/hooks';
import { useMemo } from 'react';
import { UserTier } from 'src/types/models/UserTier';
import { LabelPro } from 'src/components/LabelPro';
import { PRICE_PER_MONTH } from 'src/constants';

export const UpsellMessageBlock = () => {
  const { isOpenTier, isProTrialTier, isFreeTier, appUser } = useSession();
  const title = useMemo(() => {
    switch (appUser?.tier_id) {
      case UserTier.OPEN:
        return 'Ninja Pro Trial for free';
      case UserTier.PRO_TRIAL:
        return `Upgrade for only $${PRICE_PER_MONTH}/mo`;
      case UserTier.FREE:
        return 'Upgrade to Ninja Pro';
      case UserTier.TESTING:
      case UserTier.PAID:
      case UserTier.PRO_ZERO:
        return <LabelPro />;

      default:
        return '';
    }
  }, [appUser]);

  const description = useMemo(() => {
    switch (appUser?.tier_id) {
      case UserTier.OPEN:
        return 'Enjoy 7 days of unlimited access to Ninja Agents. No credit card needed.';
      case UserTier.PRO_TRIAL:
        return 'Gain full access to 24+ industry leading LLMs and unlimited Ninja AI agents.';
      case UserTier.FREE:
        return 'Gain full access to 24+ industry leading LLMs and unlimited Ninja AI agents.';
      case UserTier.TESTING:
      case UserTier.PAID:
      case UserTier.PRO_ZERO:
        return <LabelPro />;

      default:
        return '';
    }
  }, [appUser]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div>
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>{title}</h4>
            {isProTrialTier && <span className={styles.tag}>Trial Offer</span>}
            {isOpenTier && <span className={styles.tag}>Limited time</span>}
          </div>
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.buttonWrapper}>
          {isOpenTier && <GuestAuthLinks dataGTM="Chat-thread-signup" />}
          {(isProTrialTier || isFreeTier) && (
            <UpgradeLearnMoreButtons dataGTM="Chat-thread-upgrade" />
          )}
        </div>
      </div>
    </div>
  );
};

import { Header } from 'src/pages/AuthPages/components/Header';
import atlasSmile from 'src/images/login/atlas_smile.png';
import { FormInput } from 'src/components/FormInput';
import { WarningCircle } from '@phosphor-icons/react';
import { FormProvider } from 'src/components/FormProvider';
import React, { ReactNode, useState } from 'react';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { useForm, useWatch } from 'react-hook-form';
import { resetPassword } from 'aws-amplify/auth';
import { LoginHeader } from 'src/pages/AuthPages/components/LoginHeader';
import log from 'src/utils/logger';
import { EmailVerificationBlock } from 'src/pages/AuthPages/components/EmailVerificationBlock';
import { useLazyCheckEmailInfoQuery } from 'src/store/services';
import { toast } from 'react-toastify';

enum ForgotPasswordFields {
  EMAIL = 'email',
}

interface FormData {
  [ForgotPasswordFields.EMAIL]: string;
}

export const ForgotPasswordPage = () => {
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | null>(
    null,
  );
  const [isResetSent, setIsResetSent] = useState(false);
  const [checkEmailInfo] = useLazyCheckEmailInfoQuery();

  const methods = useForm<FormData>({
    defaultValues: {
      [ForgotPasswordFields.EMAIL]: '',
    },
  });

  const { handleSubmit, control } = methods;

  const fields = useWatch({
    control,
  });

  const resendResetPassword = async (username: string) => {
    await resetPassword({
      username,
    });
    toast('Password reset email was resent');
  };

  const submitForm = async (data: FormData) => {
    const userEmail = data[ForgotPasswordFields.EMAIL].toLowerCase();
    try {
      setErrorMessage('');
      await resetPassword({
        username: userEmail,
      });
      setIsResetSent(true);
    } catch (error) {
      if ((error as ServiceError).name === 'UserNotFoundException') {
        const { data: emailInfoData } = await checkEmailInfo(userEmail);
        if (emailInfoData?.user_exists && !emailInfoData?.user_email_verified) {
          setErrorMessage(<EmailVerificationBlock email={userEmail} />);
        } else {
          setErrorMessage('The account does not exist');
        }
      } else if (
        (error as ServiceError).name === 'NotAuthorizedException' &&
        (error as ServiceError).message ===
          'User password cannot be reset in the current state.'
      ) {
        setErrorMessage(
          'The email is registered via Single Sign-On (SSO). Please return to the login page to access your account.',
        );
      } else {
        log.error(error);
        setErrorMessage(error ? (error as ServiceError).message : 'error');
      }
    }
  };

  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<LoginHeader />} />
      <main className="nj-auth-content">
        <h3 className="nj-auth-header-secondary">
          {!isResetSent ? 'Forgot password?' : 'Check your email'}
        </h3>
        {isResetSent ? (
          <div className="nj-auth-verified-block">
            <p className="nj-auth-verified-text">
              We have sent a password reset link to &nbsp;
              {fields[ForgotPasswordFields.EMAIL]}
            </p>
            <div className="nj-auth-verified-text">
              <span>Didn't receive an email? &nbsp;</span>
              <button
                className="nj-auth-verified-button"
                onClick={() => {
                  resendResetPassword(fields[ForgotPasswordFields.EMAIL] || '');
                }}
              >
                Send a new link
              </button>
            </div>
          </div>
        ) : (
          <FormProvider<FormData> methods={methods}>
            <form onSubmit={handleSubmit(submitForm)} className="nj-auth-form">
              <div className="nj-auth-form--field">
                <legend className="nj-auth-form--legend">Email</legend>
                <FormInput
                  name={ForgotPasswordFields.EMAIL}
                  type="email"
                  className="nj-auth-form--input"
                  placeholder="Enter Email"
                  required={true}
                  hasErrorBorder={!!errorMessage}
                />
              </div>
              <button className="nj-auth-form--submit-button" type="submit">
                Send reset link
              </button>
              {!!errorMessage && (
                <div className="nj-auth-form--error-block">
                  <WarningCircle size={24} />
                  <span>{errorMessage}</span>
                </div>
              )}
            </form>
          </FormProvider>
        )}
      </main>
      <img className="nj-auth-background-image" src={atlasSmile} alt="Ninja" />
    </div>
  );
};

import { useMemo, useState, useContext } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { useAvatarPreferences, useSession, useBreakpoint } from 'src/hooks';
import { PersonaSection } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent/components/PersonaSection';
import { ConversationalToneSection } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent/components/ConversationalToneSection';
import { ConversationalStyleSection } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorContent/components/ConversationalStyleSection';
import { CommunicationStyle, SVG_SIZE_XXXL } from 'src/constants';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { AvatarSelectorMobileItem } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorButton/components/AvatarSelectorMobileItem';
import { UpsellMessage } from 'src/components/UpsellMessage';
import { AvatarImage } from 'src/components/AvatarImage';
import { VideoCallButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons';

export enum PersonaSectionItemKey {
  persona = 'persona',
  style = 'style',
  tone = 'tone',
}

export const AvatarSelectorContent = () => {
  const { avatarsSelectorRef } = useContext(ForwardRefContext);

  const [expanded, setExpanded] = useState('');
  const { appUser, isOpenTier, isFreeTier } = useSession();
  const { selectedCommunicationTone, avatarVoiceID, voiceOptions } =
    useAvatarPreferences();
  const { isMobile } = useBreakpoint();

  const selectedVoiceValue = useMemo(() => {
    return (
      voiceOptions.find((item) => item.voiceID === avatarVoiceID)
        ?.dispalyVoiceName || null
    );
  }, [voiceOptions, avatarVoiceID]);

  const getAccordionItems = useMemo(() => {
    return [
      {
        key: PersonaSectionItemKey.persona,
        title: 'Persona',
        subtitle: 'Choose AI character and voice',
        valueSubtitle: `Voice: ${selectedVoiceValue}`,
        content: <PersonaSection />,
      },
      {
        key: PersonaSectionItemKey.style,
        title: 'Conversational style',
        subtitle: 'Preference of AI generating response',
        valueSubtitle:
          CommunicationStyle[appUser?.persona?.conversational_style || '3'],
        content: <ConversationalStyleSection />,
      },
      {
        key: PersonaSectionItemKey.tone,
        title: 'Conversational tone',
        subtitle: 'Preference of AI communication type',
        valueSubtitle: selectedCommunicationTone?.title || '',
        content: <ConversationalToneSection />,
      },
    ];
  }, [
    appUser?.persona?.conversational_style,
    selectedCommunicationTone,
    selectedVoiceValue,
  ]);

  return (
    <div className="nj-accordion nj-accordion-persona" ref={avatarsSelectorRef}>
      <div className="nj-avatar-row-wrapper">
        <AvatarImage size={SVG_SIZE_XXXL} />
        <VideoCallButton />
      </div>
      {(isOpenTier || isFreeTier) && (
        <div className="nj-avatar-select-tooltip-upsell-message-wrapper">
          {isOpenTier && (
            <UpsellMessage
              dataGTMOpenTier="Avatar-selector-signup"
              // (olha): I need to keep this prop is required to bu sure we don't miss add it to all places
              dataGTMNinjaTier=""
              description="Get access to more avatars, and additional tone profiles"
            />
          )}
        </div>
      )}
      {getAccordionItems.map(
        ({ key, title, content, subtitle, valueSubtitle }) => {
          if (isMobile) {
            return (
              <AvatarSelectorMobileItem
                key={key}
                title={title}
                subtitle={subtitle}
                content={content}
              />
            );
          } else
            return (
              <CollapsedItem
                title={title}
                subtitle={subtitle}
                valueSubtitle={valueSubtitle}
                content={content}
                isExpanded={key === expanded}
                onExpand={setExpanded}
                key={key}
                expandedKey={key}
              />
            );
        },
      )}
    </div>
  );
};

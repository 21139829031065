import { Modal } from 'src/components/Modal';
import styles from './PayPerUseModal.module.scss';
import { LLMInfoTable } from 'src/components/PlanCardModal/components/LLMInfoTable';
import {
  externalLLMsQueriesPerLLM,
  imageGenLLMsImagesPerLLM,
  PRICE_PER_MONTH,
} from 'src/constants';

type PayPerUseModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PayPerUseModal = ({ isOpen, onClose }: PayPerUseModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={styles.payPerUseModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <div>
          <h4 className={styles.title}>How does it work?</h4>
          <p className={styles.description}>
            Ninja calculates the cost of each query and you use your credits
            only for the models you choose.
          </p>
        </div>
        <div className={styles.tablesWrapper}>
          <LLMInfoTable
            llmList={externalLLMsQueriesPerLLM}
            title={`Approx. queries for $${PRICE_PER_MONTH * 2}.00`}
          />
          <LLMInfoTable
            llmList={imageGenLLMsImagesPerLLM}
            title={`Approx. images for $${PRICE_PER_MONTH * 2}.00`}
          />
        </div>
      </div>
    </Modal>
  );
};

import { CompanyLinksSet } from 'src/pages/AuthPages/components/CompanyLinksSet';
import { useSession } from 'src/hooks';
import { UserTier } from 'src/types/models/UserTier';
import { LabelPro } from 'src/components/LabelPro';
import { LabelLimitedTime } from 'src/components/LabelLimitedTime';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';
import { PRICE_PER_MONTH } from 'src/constants';
import { LabelEnterpriseTrial } from 'src/components/LabelEnterpriseTrial';

export const TiersAuthFooter = () => {
  const { appUser } = useSession();

  const renderContent = () => {
    switch (appUser.tier_id) {
      case UserTier.OPEN:
        return (
          <>
            <h4 className="nj-tier-auth-footer-title">
              <span>Ninja Pro Trial for free</span>
              <LabelLimitedTime />
            </h4>

            <p className="nj-tier-auth-footer-description">
              Enjoy 7 days of unlimited access to Ninja Agents. No credit card
              needed.
            </p>

            <GuestAuthLinks dataGTM="Left-panel-signup" />
          </>
        );
      case UserTier.FREE:
      case UserTier.PRO_TRIAL:
        return (
          <>
            {/* {isProTrialTier ? (
              // temporary show coupon for all tiers
              <h4 className="nj-tier-auth-footer-title">
                <span>{`Upgrade for only $${PRICE_PER_MONTH}/mo`}</span>
                <LabelLimitedTime />
              </h4>
            ) : (
              <h4 className="nj-tier-auth-footer-title">
                <span>Upgrade to Ninja Pro</span>
              </h4>
            )} */}

            <h4 className="nj-tier-auth-footer-title">
              <span>{`Upgrade for only $${PRICE_PER_MONTH}/mo`}</span>
              <LabelLimitedTime />
            </h4>

            <p className="nj-tier-auth-footer-description">
              Gain full access to 24+ industry leading LLMs and unlimited Ninja
              AI agents.
            </p>

            <UpgradeLearnMoreButtons dataGTM="Left-panel-upgrade" />
          </>
        );
      case UserTier.PAID:
      case UserTier.TESTING:
        return (
          <div className="nj-tier-auth-footer-current-plan-row">
            <span>Current plan:</span>
            <LabelPro />
          </div>
        );
      case UserTier.ENTERPRISE_TRIAL:
        return (
          <div className="nj-tier-auth-footer-current-plan-row">
            <span>Current plan:</span>
            <LabelEnterpriseTrial />
          </div>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className="nj-tier-auth-footer">
      {renderContent()}

      <div className="nj-tier-auth-footer-links-wrapper">
        <CompanyLinksSet />
      </div>
    </div>
  );
};

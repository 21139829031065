import { addUserIdToGTMDataLayer, get_access_token } from 'src/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUser } from 'src/types';
import { env } from 'src/env';

const API_V2_URL_USERS = `${env.REACT_APP_NINJA_API_V2_URL}/users`;

export const fetchUserById = createAsyncThunk<ApiUser, string>(
  'users/fetchUserById',
  async (userId: string, { rejectWithValue }) => {
    const token = await get_access_token();
    const request = new Request(`${API_V2_URL_USERS}/${userId}`, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        const user: ApiUser = await response.json();

        const { user_id, tier_id, idp, email, first_name, last_name } = user;
        // Google Tag Manager add user_id, tier_id and login_type to dataLayer
        addUserIdToGTMDataLayer({
          user_id,
          tier_id,
          login_type: idp,
          user_email: email,
          user_first_name: first_name || '',
          user_last_name: last_name || '',
        });

        return user;
      } else {
        return rejectWithValue(
          `GET users/${userId} failed to fetch response from the server.`,
        );
      }
    } catch (e: unknown) {
      return rejectWithValue(`Cannot fetch user by email from API.`);
    }
  },
);

import { Header } from 'src/pages/AuthPages/components/Header';
import { SignupHeader } from 'src/pages/AuthPages/components/SignupHeader';
import atlasSmile from 'src/images/login/atlas_smile.png';
import { useBreakpoint, useQueryParams } from 'src/hooks';
import { EmailVerificationBlock } from 'src/pages/AuthPages/components/EmailVerificationBlock';

export const VerifiedPage = () => {
  const { isMobileOrTablet } = useBreakpoint();
  const { searchParams } = useQueryParams();

  const email = searchParams.email;

  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<SignupHeader />} />
      <main className="nj-auth-content">
        <h1 className="nj-auth-header-blue-gradient">Meet Ninja</h1>
        <h2 className="nj-auth-header-small">Your Autonomous AI Agent</h2>
        <h3 className="nj-auth-header-secondary">Verify your email</h3>
        <EmailVerificationBlock email={email} />
      </main>
      {!isMobileOrTablet && (
        <img
          className="nj-auth-background-image"
          src={atlasSmile}
          alt="Ninja"
        />
      )}
    </div>
  );
};

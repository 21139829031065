import { useSession } from 'src/hooks';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

type UpsellMessageProps = {
  title?: string;
  dataGTMOpenTier: string;
  dataGTMNinjaTier: string;
  description: string;
  className?: string;
};
export const UpsellMessage = ({
  title,
  dataGTMOpenTier,
  dataGTMNinjaTier,
  description,
  className,
}: UpsellMessageProps) => {
  const { isOpenTier, isFreeTier, isProTrialTier } = useSession();
  const navigate = useNavigate();

  const handleUpgradeClick = () => {
    navigate(AppRoutes.PAYMENT);
  };

  return (
    <div
      className={classNames(
        'nj-upsell-message-wrapper',
        { isOpenTier },
        className,
      )}
    >
      <div className="nj-upsell-message-text-block-wrapper">
        {title && <h4 className="nj-upsell-message-title">{title}</h4>}
        <p
          className={
            !!title
              ? 'nj-upsell-message-description'
              : 'nj-upsell-message-main-text'
          }
        >
          {description}
        </p>
      </div>
      <div className="nj-upsell-message-button-wrapper">
        {isOpenTier && (
          <Link
            id={dataGTMOpenTier}
            to={AppRoutes.SIGN_UP}
            className="nj-button-try-pro"
          >
            Ninja Pro Trial for free
          </Link>
        )}
        {(isFreeTier || isProTrialTier) && (
          <button
            type="button"
            onClick={handleUpgradeClick}
            className="nj-button-try-pro"
            id={dataGTMNinjaTier}
          >
            Upgrade to Pro
          </button>
        )}
      </div>
    </div>
  );
};

import { Link } from 'react-router-dom';
import { useBreakpoint } from 'src/hooks';
import { AppRoutes } from 'src/types';

export const SignupHeader = () => {
  const { isMobile } = useBreakpoint();

  return (
    <div className="nj-auth-header-right-side">
      {!isMobile && <span>Already have a Beta account?</span>}
      <Link
        to={AppRoutes.LOGIN}
        className="nj-button nj-auth-sign-in-up-button nj-auth-login-button"
      >
        Log in
      </Link>
    </div>
  );
};

import { RenewEarlyButton } from 'src/components/RenewEarlyButton';
import styles from './LowCreditsSection.module.scss';

export const LowCreditsSection = () => {
  return (
    <div className={styles.root}>
      <h4 className={styles.title}>Credits are running low</h4>
      <p className={styles.description}>
        Renew your subscription early to continue access to Pro features.
      </p>
      <RenewEarlyButton className={styles.button} />
    </div>
  );
};

import { useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import {
  TaskState,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  ChitChatCard,
  ImageCard,
} from 'src/types';
import { ResearchTaskCreationCard as ResearchCreationCardEnum } from 'src/types/models/ResearchTaskCreationCard';
import { useCurrentConversationTasks } from 'src/hooks';
import { TaskProgressIndicator } from '../TaskProgressIndicator';
import { ANIMATION_TIMEOUT } from 'src/constants';

interface TaskProgressSectionProps {
  data:
    | ResearchTaskCreationCard
    | SchedulerCreationCard
    | ChitChatCard
    | ImageCard;
}

export const TaskProgressSection = ({ data }: TaskProgressSectionProps) => {
  const { task_id, progress, payload_type } = data;
  const { getTaskFromConversationById } = useCurrentConversationTasks();
  const containerRef = useRef<HTMLDivElement>(null);

  const taskState = useMemo(() => {
    return getTaskFromConversationById(task_id)?.state || TaskState.IN_PROGRESS;
  }, [getTaskFromConversationById, task_id]);

  const isSectionVisible = useMemo(
    () => taskState !== TaskState.DONE,
    [taskState],
  );

  if (!task_id) {
    return null;
  }

  return (
    <CSSTransition
      in={isSectionVisible}
      timeout={ANIMATION_TIMEOUT}
      classNames="nj-animate-fade-out"
      unmountOnExit
      nodeRef={containerRef}
    >
      <div
        ref={containerRef}
        className={classNames('nj-thread-combined-card--progress-wrapper', {
          researcher:
            payload_type ===
            ResearchCreationCardEnum.payload_type.RESEARCH_TASK_CREATION_CARD,
        })}
      >
        <TaskProgressIndicator
          messageActions={progress?.actions}
          taskState={taskState}
          taskId={task_id}
        />
      </div>
    </CSSTransition>
  );
};

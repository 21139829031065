import { Header } from 'src/pages/AuthPages/components/Header';
import atlasSmile from 'src/images/login/atlas_smile.png';
import {
  DEFAULT_PRIVACY_POLICY_LINK,
  DEFAULT_TERMS_OF_SERVICE_LINK,
} from 'src/constants/externalLinks';
import AuthContext from 'src/contexts/AuthContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Envelope } from '@phosphor-icons/react';
import { LoginHeader } from 'src/pages/AuthPages/components/LoginHeader';
import { SSOProvider } from 'src/types';
import { SSOButton } from 'src/pages/AuthPages/components/SSOButton';

// todo: needs to refactoring this component (duplicated buttons, functions)
export const LoginPage = () => {
  const { setAuthLoading } = useContext(AuthContext);

  return (
    <div className="nj-auth-layout nj-beta">
      <Header rightSide={<LoginHeader />} />
      <main className="nj-auth-content">
        <h1 className="nj-auth-header-main">Welcome back!</h1>
        <h3 className="nj-auth-header-secondary">Login to your account</h3>
        <div className="nj-auth-sign-in-up-buttons">
          <SSOButton
            ssoProvider={SSOProvider.GOOGLE}
            isSignUp={false}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.MICROSOFT}
            isSignUp={false}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.APPLE}
            isSignUp={false}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.AMAZON}
            isSignUp={false}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.LINKEDIN}
            isSignUp={false}
            setAuthLoading={setAuthLoading}
          />
          <SSOButton
            ssoProvider={SSOProvider.FACEBOOK}
            isSignUp={false}
            setAuthLoading={setAuthLoading}
          />

          <div className="nj-auth-separator-block">
            <div className="nj-auth-separator-block-line" />
            <span>OR</span>
            <div className="nj-auth-separator-block-line" />
          </div>
          <Link className="nj-auth-sign-in-up-button" to="/login-with-email">
            <div className="nj-button-content-wrapper">
              <Envelope size={24} />
              <span className="nj-button-contents">Continue with Email</span>
            </div>
          </Link>
        </div>
        <div className="nj-auth-terms-links-row">
          <a
            href={DEFAULT_TERMS_OF_SERVICE_LINK}
            target="_blank"
            rel="noreferrer"
            className="nj-auth-terms-link"
          >
            Terms of Service
          </a>
          <div className="nj-vertical-line" />
          <a
            href={DEFAULT_PRIVACY_POLICY_LINK}
            target="_blank"
            rel="noreferrer"
            className="nj-auth-terms-link"
          >
            Privacy Policy
          </a>
        </div>
      </main>
      <img className="nj-auth-background-image" src={atlasSmile} alt="Ninja" />
    </div>
  );
};

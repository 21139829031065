import React, { useMemo } from 'react';
import GoogleLogo from 'src/images/logos/google-logo.svg';
import FacebookLogo from 'src/images/logos/facebook-logo.png';
import MicrosoftLogo from 'src/images/logos/microsoft-logo.png';
import LinkedInLogo from 'src/images/logos/LI-In-Bug.png';
import AppleLogo from 'src/images/logos/apple-logo.png';
import AmazonLogo from 'src/images/logos/amazon-logo.png';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent, SSOProvider } from 'src/types';
import { signInWithRedirect } from 'aws-amplify/auth';

type SSOButtonProps = {
  ssoProvider: SSOProvider;
  isSignUp: boolean;
  showInProd?: boolean;
  setAuthLoading: (value: boolean) => void;
};

export const SSOButton = ({
  ssoProvider,
  isSignUp,
  showInProd = true,
  setAuthLoading,
}: SSOButtonProps) => {
  const showLoginButton =
    process.env.REACT_APP_ENVIRONMENT !== 'production' ? true : showInProd;

  const ssoData = useMemo(() => {
    switch (ssoProvider) {
      case SSOProvider.GOOGLE:
        return {
          icon: GoogleLogo,
          displayName: 'Google',
          gtmEvent: GTMEvent.GOOGLE_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.AMAZON:
        return {
          icon: AmazonLogo,
          displayName: 'Amazon',
          gtmEvent: GTMEvent.AMAZON_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.APPLE:
        return {
          icon: AppleLogo,
          displayName: 'Apple',
          gtmEvent: GTMEvent.APPLE_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.FACEBOOK:
        return {
          icon: FacebookLogo,
          displayName: 'Facebook',
          gtmEvent: GTMEvent.FACEBOOK_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.MICROSOFT:
        return {
          icon: MicrosoftLogo,
          displayName: 'Microsoft',
          gtmEvent: GTMEvent.MICROSOFT_SSO_SIGNUP_CLICKS,
        };
      case SSOProvider.LINKEDIN:
        return {
          icon: LinkedInLogo,
          displayName: 'LinkedIn',
          gtmEvent: GTMEvent.LINKED_IN_SSO_SIGNUP_CLICKS,
        };
      default:
        throw new Error('Unsupported SSOProvider');
    }
  }, [ssoProvider]);

  const labelText =
    (isSignUp ? 'Sign up with ' : 'Continue with ') + `${ssoData.displayName}`;

  const handleLoginClick = async () => {
    sendGTMEvent(ssoData.gtmEvent);

    setAuthLoading(true);
    signInWithRedirect({
      provider: {
        custom: ssoProvider,
      },
    });
  };

  if (!showLoginButton) return <></>;

  return (
    <button
      className="nj-auth-sign-in-up-button nj-google-button"
      onClick={handleLoginClick}
    >
      <div className="nj-google-button-state"></div>
      <div className="nj-google-button-content-wrapper">
        <div>
          <img
            className="nj-auth-sign-in-up-button-logo"
            src={ssoData.icon}
            alt={ssoData.displayName}
          />
        </div>
        <span className="nj-google-button-contents">{labelText}</span>
        <span style={{ display: 'none' }}>{labelText}</span>
      </div>
    </button>
  );
};

import { configureStore, AnyAction, Reducer } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  persistCombineReducers,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'reduxjs-toolkit-persist';
import createWebStorage from 'reduxjs-toolkit-persist/lib/storage/createWebStorage';
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2';
import logoutReducer, { resetStore } from './slices/logoutSlice';
import userChatsReducer, {
  setThreadStatus,
  setShouldAnimate,
  setAutoScrollRegistry,
  removeAutoScrollRegistry,
  userChatsState,
  userChatsStateThreadsStatusRegistry,
} from './slices/userChatsSlice';
import type {
  UserChatsState,
  ThreadsStatusRegistry,
} from './slices/userChatsSlice';
import { fetchUserById } from './thunks';
import sessionReducer, {
  resetSession,
  sessionState,
  sessionColorThemeState,
  setNotification,
  clearNotification,
  setAppUser,
  setIsDarkTheme,
  setSocketConnected,
  setOnboardingHintStep,
  setCurrentTaskId,
  showAccessRequestOnSchedulerTask,
  setIsAccessModalShown,
  setFirstUserQuery,
  setRecurringUpsellModalControls,
  setWelcomeModalControls,
} from './slices/sessionSlice';
import settingsReducer, {
  settingsState,
  resetSettings,
  setActiveSettingsTab,
} from './slices/settingsSlice';
import avatarReducer, {
  setAvatarQueue,
  clearAvatarQueue,
  setChatMode,
  avatarState,
} from './slices/avatarSlice';
import interfaceControlReducer, {
  setChatModeSidePanel,
  setIsSettingsPanelExpanded,
  setIsTasksListExpanded,
  interfaceControlState,
} from './slices/interfaceControlSlice';
import modalsControlReducer, {
  toggleWelcomeModal,
  modalsControlState,
} from './slices/modalsControlSlice';
import { apiServices } from './services/index';

const createNoopStorage = () => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getItem(_key: string) {
      return Promise.resolve(null);
    },
    setItem(_key: string, value: string) {
      return Promise.resolve(value);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeItem(_key: string) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

const persistConfig = {
  key: 'ninjaRoot',
  storage,
  timeout: 2000, //Set the timeout function to 2 seconds for the splash screen
  stateReconciler: autoMergeLevel2,
  whitelist: ['session'],
};

const apiReducers = apiServices.reduce(
  (acc, api) => {
    acc[api.reducerPath] = api.reducer;
    return acc;
  },
  {} as Record<string, (typeof apiServices)[number]['reducer']>,
);

const persistedReducer = persistCombineReducers(persistConfig, {
  logout: logoutReducer,
  session: sessionReducer,
  settings: settingsReducer,
  avatar: avatarReducer,
  interfaceControl: interfaceControlReducer,
  modalsControl: modalsControlReducer,
  userChats: userChatsReducer,
  ...apiReducers,
});

const rootReducer: Reducer = (
  state: ReturnType<typeof persistedReducer>,
  action: AnyAction,
) => {
  if (action.type === 'logout/resetStore') {
    return persistedReducer({ _persist: state._persist }, action);
  }
  return persistedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(...apiServices.map((api) => api.middleware)),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(...apiServices.map((api) => api.middleware)),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { fetchUserById };
export { resetStore };
export {
  resetSession,
  setNotification,
  clearNotification,
  setAppUser,
  setIsDarkTheme,
  setSocketConnected,
  setOnboardingHintStep,
  setCurrentTaskId,
  showAccessRequestOnSchedulerTask,
  setIsAccessModalShown,
  setFirstUserQuery,
  setRecurringUpsellModalControls,
  /**
   * @deprecated
   */
  setWelcomeModalControls,
  sessionState,
  sessionColorThemeState,
};
export { setChatMode, setAvatarQueue, clearAvatarQueue, avatarState };
export { resetSettings, setActiveSettingsTab, settingsState };
export {
  setThreadStatus,
  setShouldAnimate,
  setAutoScrollRegistry,
  removeAutoScrollRegistry,
  UserChatsState,
  userChatsState,
  userChatsStateThreadsStatusRegistry,
  ThreadsStatusRegistry,
};
export {
  setChatModeSidePanel,
  setIsSettingsPanelExpanded,
  setIsTasksListExpanded,
  interfaceControlState,
};
export { toggleWelcomeModal, modalsControlState };

export const persistor = persistStore(store);

export default store;

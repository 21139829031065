import { CSSTransition } from 'react-transition-group';
import {
  ANIMATION_TIMEOUT,
  AvailableImageGenerationModels,
  ImageGenerationModels,
  SVG_SIZE_L,
} from 'src/constants';
import './ModalWithGallery.scss';
import { MouseEvent, useContext, useMemo, useRef, useState } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  ExternalModelReference as ExternalModelReferenceType,
  isExternalModelReference,
  isImageGeneratedItem,
  isSearchImageReference,
  isSearchVideoReference,
  isSearchWebPageReference,
  ModalGalleryItemType,
  ModalGalleryType,
  ImageGenRenderData,
  ImageGenerationItem,
  ExternalModelReference,
  AppRoutes,
} from 'src/types';
import { ResearchMediaThumbnail } from 'src/components/FlatAppearance/components/ThreadResearchCard/components/ResearchMediaThumbnail';
import classNames from 'classnames';
import { ViewInBrowserButton } from 'src/components/ModalWithGallery/components/ViewInBrowserButton';
import { LazyReactPlayer } from 'src/components/LazyReactPlayer';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { CloseButton } from 'src/components/ModalWithGallery/components/CloseButton';
import { CaretLeft, CaretRight, Stack, Image } from '@phosphor-icons/react';
import { ExternalModelThumbnail } from 'src/components/FlatAppearance/components/ExternalModelReferencesList/components/ExternalModelThumbnail';
import { ReferenceItem } from 'src/components/ModalWithGallery/components/ReferenceItem';
import { Markdown } from 'src/components/Markdown';
import { ReferenceIframe } from 'src/components/ModalWithGallery/components/ReferenceIframe';
import { CopyButton } from 'src/components/ModalWithGallery/components/CopyButton/CopyButton';
import { useHotkeys } from 'react-hotkeys-hook';
import { Icon } from 'src/components/Icon';
import { useBreakpoint, useSession, useVisible } from 'src/hooks';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';
import { MobileListButton } from 'src/components/ModalWithGallery/components/MobileListButton';
import { MobileModalOverlayList } from 'src/components/ModalWithGallery/components/MobileModalOverlayList';
import { DownloadButton } from 'src/components/ModalWithGallery/components/DownloadButton';
import { useDownloadFile } from 'src/hooks/useDownloadFile';
import { ImageBroken } from '@phosphor-icons/react/dist/ssr';
import { EditImageButton } from 'src/components/ModalWithGallery/components/EditImageButton';
import { UserMessageContent } from '../UserMessage/components/UserMessageContent';
import { useGetUserTaskCostsInfoQuery } from 'src/store/services';
import { LoadingLabel } from 'src/components/LoadingLabel';
import { getPriceWithCurrency } from 'src/utils';
import { useNavigate } from 'react-router-dom';

const SVG_SIZE = 32;
const SVG_SIZE_MOBILE = 24;
const SVG_SIZE_BIG = 64;
const IMAGE_URL_REGEX = /(https?:\/\/.*\.(?:png|jpg|jpeg))/i;

export const ModalWithGallery = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { downloadImage } = useDownloadFile();
  const navigate = useNavigate();

  const { isMobileOrTablet } = useBreakpoint();
  const {
    isVisible: isOpenMobileListPanel,
    handleVisibilityRemove: closeMobileListPanel,
    handleVisibilityToggle: toggleIsOpenMobileListPanel,
  } = useVisible();
  const {
    isVisibleModalGallery,
    currentModalGalleryType,
    currentListOfGalleryItems,
    defaultSelectedGalleryIndex,
    onChangeModalGalleryData,
    currentTaskId,
  } = useContext(SessionContext);
  const [selectedItemIndex, setSelectedItemIndex] = useState(
    defaultSelectedGalleryIndex,
  );
  const { appUser, isOpenTier, isFreeTier } = useSession();
  const swiperRef = useRef<SwiperRef | null>(null);

  const {
    data: taskCostsData,
    isLoading: isTaskCostsLoading,
    isFetching: isTaskCostsFetching,
  } = useGetUserTaskCostsInfoQuery(
    { user_id: appUser?.user_id || '', task_id: currentTaskId || '' },
    {
      skip:
        !(
          currentModalGalleryType === ModalGalleryType.GENERATED_IMAGES ||
          currentModalGalleryType === ModalGalleryType.EXTERNAL_MODELS
        ) ||
        isOpenTier ||
        isFreeTier,
    },
  );

  const currentSelectedItem = useMemo(
    () => currentListOfGalleryItems[selectedItemIndex],
    [selectedItemIndex, currentListOfGalleryItems],
  );

  const handleChangeCurrentIndex = (index: number) => {
    if (isMobileOrTablet && isOpenMobileListPanel) {
      closeMobileListPanel();
    }
    setSelectedItemIndex(index);
    swiperRef?.current?.swiper?.slideTo(index);
  };

  useHotkeys('esc', () => {
    onChangeModalGalleryData(false, null, [], 0);
  });

  useHotkeys('left', () => {
    if (selectedItemIndex !== 0) {
      handleChangeCurrentIndex(selectedItemIndex - 1);
    }
  });

  useHotkeys('right', () => {
    if (selectedItemIndex + 1 !== currentListOfGalleryItems.length) {
      handleChangeCurrentIndex(selectedItemIndex + 1);
    }
  });

  useHotkeys('d', () => {
    if (currentModalGalleryType === ModalGalleryType.GENERATED_IMAGES) {
      downloadImage(currentSelectedItem.url);
    }
  });

  const handleSlideChange = () => {
    const direction = swiperRef?.current?.swiper.swipeDirection;

    if (direction === 'next') {
      if (selectedItemIndex + 1 !== currentListOfGalleryItems.length) {
        handleChangeCurrentIndex(selectedItemIndex + 1);
      }
    } else if (direction === 'prev') {
      if (selectedItemIndex !== 0) {
        handleChangeCurrentIndex(selectedItemIndex - 1);
      }
    }
  };

  const handleDisabledOverlayClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onChangeModalGalleryData(false, null, [], 0);
    if (isOpenTier) {
      navigate(AppRoutes.SIGN_UP);
    }
    if (isFreeTier) {
      navigate(AppRoutes.PAYMENT);
    }
  };

  const renderGalleryItem = (item: ModalGalleryItemType, index: number) => {
    switch (currentModalGalleryType) {
      case ModalGalleryType.IMAGES_VIDEOS:
        return (
          <ResearchMediaThumbnail
            thumbnail={isSearchVideoReference(item) ? item.thumbnail : item.url}
            title={item.title}
            isVideo={isSearchVideoReference(item)}
            onClick={() => handleChangeCurrentIndex(index)}
          />
        );
      case ModalGalleryType.EXTERNAL_MODELS:
        if (isExternalModelReference(item)) {
          const isTaskCostPending =
            isTaskCostsLoading ||
            isTaskCostsFetching ||
            taskCostsData?.chargeable_costs.length === 0;

          const selectedValue = (taskCostsData?.chargeable_costs || []).find(
            (costItem) =>
              costItem.item.name === (item as ExternalModelReference).url,
          );

          const price = getPriceWithCurrency(
            selectedValue?.cost?.amount || 0,
            selectedValue?.cost?.currency,
          );

          return (
            <ExternalModelThumbnail
              isActive={index === selectedItemIndex}
              title={item.title}
              icon={item.icon}
              icon_name={item.icon_name}
              disabled={!item.content}
              onClick={() => handleChangeCurrentIndex(index)}
              isCostPending={isTaskCostPending}
              price={price}
            />
          );
        }
        return null;
      case ModalGalleryType.REFERENCES:
        return isSearchWebPageReference(item) ? (
          <ReferenceItem
            isActive={index === selectedItemIndex}
            title={`${index + 1}. ${item.title}`}
            onClick={() => handleChangeCurrentIndex(index)}
          />
        ) : null;
      case ModalGalleryType.GENERATED_IMAGES:
        if (isImageGeneratedItem(item)) {
          const isTaskCostPending =
            isTaskCostsLoading ||
            isTaskCostsFetching ||
            taskCostsData?.chargeable_costs.length === 0;

          const isNinjaItem = AvailableImageGenerationModels.includes(
            (item as ImageGenerationItem).source as ImageGenerationModels,
          );

          const selectedValue = (taskCostsData?.chargeable_costs || []).find(
            (costItem) =>
              costItem.item.name === (item as ImageGenerationItem).source,
          );

          const price = getPriceWithCurrency(
            selectedValue?.cost?.amount || 0,
            selectedValue?.cost?.currency,
          );

          return (
            <div className="nj-generated-image-wrapper">
              <div className="nj-generated-image-title-wrapper">
                <h4 className="nj-generated-image-title">
                  {item.icon && <Icon type={item.icon} size={20} />}
                  <span className="ellipsis">{item.title}</span>
                </h4>
                {!isOpenTier && !isFreeTier && (
                  <>
                    {isTaskCostPending && !isNinjaItem && (
                      <div className="nj-generated-image-cost-loading-wrapper">
                        <LoadingLabel
                          text="Calculating costs"
                          isSelected={selectedItemIndex === index}
                        />
                      </div>
                    )}
                    {isNinjaItem && (
                      <span className="nj-generated-image-cost-label pending">
                        Included
                      </span>
                    )}
                    {!isTaskCostPending && !isNinjaItem && (
                      <span className="nj-generated-image-cost-label pending">
                        {price}
                      </span>
                    )}
                  </>
                )}
              </div>

              <ResearchMediaThumbnail
                thumbnail={item.url}
                title={item.title}
                onClick={() => handleChangeCurrentIndex(index)}
                isPixPro={
                  (item as ImageGenerationItem).source ===
                  ImageGenerationModels.PIX_PRO
                }
                isDisabled={(item as ImageGenerationItem).isDisabled}
              />
            </div>
          );
        }
        return null;
      default:
        return <></>;
    }
  };

  const renderTitle = () => {
    switch (currentModalGalleryType) {
      case ModalGalleryType.IMAGES_VIDEOS:
        return (
          <h4 className="nj-modal-gallery-title-text ellipsis">
            {currentSelectedItem.title}
          </h4>
        );
      case ModalGalleryType.EXTERNAL_MODELS:
        return (
          <div className="nj-modal-overlay-content-title-wrapper">
            <Stack size={SVG_SIZE} />
            <h4 className="nj-modal-gallery-title-text ellipsis">
              External model results
            </h4>
          </div>
        );
      case ModalGalleryType.REFERENCES:
        return (
          <div className="nj-modal-overlay-content-title-wrapper">
            {isSearchWebPageReference(currentSelectedItem) &&
              currentSelectedItem.favicon && (
                <img
                  src={currentSelectedItem.favicon}
                  alt={currentSelectedItem.title}
                  className="nj-modal-gallery-title-icon"
                />
              )}
            <h4 className="nj-modal-gallery-title-text">
              {currentSelectedItem.title}
            </h4>
          </div>
        );
      case ModalGalleryType.GENERATED_IMAGES:
        return (
          <div className="nj-modal-overlay-content-title-wrapper">
            <Image size={SVG_SIZE} />
            <h4 className="nj-modal-gallery-title-text ellipsis">
              Image generators
            </h4>
          </div>
        );
      default:
        return <></>;
    }
  };

  const renderActivityButtons = () => {
    switch (currentModalGalleryType) {
      case ModalGalleryType.IMAGES_VIDEOS:
        return <ViewInBrowserButton url={currentSelectedItem.url} />;
      case ModalGalleryType.EXTERNAL_MODELS:
        return (
          <>
            <CopyButton
              text={
                isExternalModelReference(currentSelectedItem)
                  ? `${currentSelectedItem.title} \n\n ${currentSelectedItem.content}`
                  : ''
              }
            />
          </>
        );
      case ModalGalleryType.REFERENCES:
        return <ViewInBrowserButton url={currentSelectedItem.url} />;
      case ModalGalleryType.GENERATED_IMAGES:
        const isDisabled = (currentSelectedItem as ImageGenerationItem)
          .isDisabled;
        return (
          <>
            {IMAGE_URL_REGEX.test(currentSelectedItem.url) &&
              !isOpenTier &&
              !isDisabled && <EditImageButton url={currentSelectedItem.url} />}
            {!isOpenTier && !isDisabled && (
              <DownloadButton url={currentSelectedItem.url} />
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const renderMainItemView = () => {
    switch (currentModalGalleryType) {
      case ModalGalleryType.IMAGES_VIDEOS:
        if (isSearchVideoReference(currentSelectedItem)) {
          return (
            <LazyReactPlayer
              url={currentSelectedItem.url}
              thumbnail={currentSelectedItem.thumbnail}
              width={isMobileOrTablet ? '90%' : '70%'}
              height={isMobileOrTablet ? '60%' : '90%'}
              className="nj-modal-gallery-player"
              controls
              onClick={(e: MouseEvent) => e.stopPropagation()}
            />
          );
        } else if (isSearchImageReference(currentSelectedItem)) {
          return (
            <img
              src={currentSelectedItem.url}
              alt={currentSelectedItem.title}
              className="nj-modal-gallery-image"
              onClick={(e) => e.stopPropagation()}
            />
          );
        }
        return <div></div>;
      case ModalGalleryType.EXTERNAL_MODELS:
        const LogoIcon = (currentSelectedItem as ExternalModelReferenceType)
          .icon;
        return isExternalModelReference(currentSelectedItem) ? (
          <div
            className="nj-modal-gallery-external-models-wrapper"
            id="printable-area"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="nj-modal-gallery-external-models-title">
              {LogoIcon ? (
                <LogoIcon />
              ) : (
                currentSelectedItem.icon_name && (
                  <Icon size={SVG_SIZE} type={currentSelectedItem.icon_name} />
                )
              )}

              <h4 className="nj-modal-gallery-external-models-title-text">
                {currentSelectedItem.title}
              </h4>
            </div>
            {currentSelectedItem.content ? (
              <div className={isMobileOrTablet ? '' : 'swiper-no-swiping'}>
                {currentSelectedItem.originalQuery && (
                  <h4 className="nj-modal-gallery-external-models-title-text">
                    <UserMessageContent
                      content={currentSelectedItem.originalQuery}
                    />
                  </h4>
                )}

                <Markdown>{currentSelectedItem.content}</Markdown>
              </div>
            ) : (
              <ProgressBar count={3} />
            )}
          </div>
        ) : null;
      case ModalGalleryType.REFERENCES:
        return (
          <ReferenceIframe
            url={currentSelectedItem.url}
            onClick={(e: MouseEvent) => e.stopPropagation()}
          />
        );
      case ModalGalleryType.GENERATED_IMAGES:
        return IMAGE_URL_REGEX.test(currentSelectedItem.url) ? (
          <div className="nj-modal-gallery-wrapper-image-gen">
            <img
              src={currentSelectedItem.url}
              alt={currentSelectedItem.title}
              className="nj-modal-gallery-image-gen"
              onClick={(e) => e.stopPropagation()}
            />
            <div className="nj-modal-gallery-image-gen--model-label">
              <Icon
                size={SVG_SIZE_L}
                type={
                  (currentSelectedItem as ImageGenRenderData).icon || undefined
                }
              />
              <span>{(currentSelectedItem as ImageGenRenderData).title}</span>
            </div>
            {(currentSelectedItem as ImageGenerationItem).isDisabled && (
              <button
                type="button"
                className="nj-modal-gallery-wrapper-image-gen-disabled-overlay"
                onClick={handleDisabledOverlayClick}
              >
                <span>Unlock to see</span>
                <div className="nj-thread-research-media--thumbnail-image-disabled-overlay-button">
                  {isOpenTier ? 'Sign up' : 'Upgrade'}
                </div>
              </button>
            )}
          </div>
        ) : (
          <div className="nj-modal-broken-image">
            <ImageBroken size={SVG_SIZE_BIG} />
            <span className="nj-modal-broken-image-text">
              Unable to generate image
            </span>
          </div>
        );
      default:
        return <></>;
    }
  };

  const navigationButtons = (
    <>
      <button
        className="swiper-prev-button"
        title="Press Left"
        disabled={selectedItemIndex === 0}
        onClick={(e) => {
          e.stopPropagation();
          handleChangeCurrentIndex(selectedItemIndex - 1);
        }}
      >
        <CaretLeft size={isMobileOrTablet ? SVG_SIZE_MOBILE : SVG_SIZE} />
      </button>
      {isMobileOrTablet && (
        <MobileListButton onClick={toggleIsOpenMobileListPanel} />
      )}
      <button
        className="swiper-next-button"
        title="Press Right"
        disabled={selectedItemIndex + 1 === currentListOfGalleryItems.length}
        onClick={(e) => {
          e.stopPropagation();
          handleChangeCurrentIndex(selectedItemIndex + 1);
        }}
      >
        <CaretRight size={isMobileOrTablet ? SVG_SIZE_MOBILE : SVG_SIZE} />
      </button>
    </>
  );

  return (
    <CSSTransition
      in={isVisibleModalGallery}
      timeout={ANIMATION_TIMEOUT}
      classNames="nj-animate-fade"
      onEnter={() => {
        setSelectedItemIndex(defaultSelectedGalleryIndex);
        if (swiperRef?.current?.swiper) {
          swiperRef?.current?.swiper?.slideTo(defaultSelectedGalleryIndex);
        }
      }}
      onExited={() => {
        setSelectedItemIndex(0);
      }}
      unmountOnExit
      nodeRef={containerRef}
    >
      <div ref={containerRef} className="nj-modal-overlay">
        <div
          className="nj-modal-overlay-content"
          onClick={() => onChangeModalGalleryData(false, null, [], 0)}
        >
          <div className="nj-modal-overlay-content-header">
            <div
              className="nj-modal-overlay-content-title-wrapper"
              onClick={(e) => e.stopPropagation()}
            >
              {renderTitle()}
            </div>
            <div className="nj-modal-overlay-content-activity-buttons-wrapper">
              {renderActivityButtons()}
              <CloseButton />
            </div>
          </div>
          {currentListOfGalleryItems.length > 0 && (
            <div
              className={classNames('nj-modal-overlay-content-body', {
                wide:
                  currentModalGalleryType !== ModalGalleryType.IMAGES_VIDEOS,
              })}
            >
              <Swiper
                centeredSlides={true}
                spaceBetween={50}
                slidesPerView={1}
                initialSlide={selectedItemIndex}
                direction="horizontal"
                className="nj-modal-content--carousel-container"
                ref={swiperRef}
                onSlideChange={handleSlideChange}
                allowSlideNext={isMobileOrTablet}
                allowSlidePrev={isMobileOrTablet}
              >
                {currentListOfGalleryItems.map((item) => (
                  <SwiperSlide key={item.url}>
                    {renderMainItemView()}
                  </SwiperSlide>
                ))}
                {!isMobileOrTablet && navigationButtons}
              </Swiper>
              {isMobileOrTablet && (
                <div className="nj-modal-overlay-content-footer">
                  {navigationButtons}
                </div>
              )}
            </div>
          )}
        </div>
        {!isMobileOrTablet && (
          <div className="nj-modal-overlay-right-side">
            {currentListOfGalleryItems.map((item, i) => (
              <div
                className={classNames('nj-modal-overlay-list-item-wrapper', {
                  selected: selectedItemIndex === i,
                })}
                key={`${item.url}_${i}`}
              >
                {renderGalleryItem(item, i)}
              </div>
            ))}
          </div>
        )}
        {isMobileOrTablet && (
          <MobileModalOverlayList
            isOpen={isOpenMobileListPanel}
            onClose={closeMobileListPanel}
          >
            <div className="nj-modal-overlay-mobile-list-panel">
              <div
                className={classNames(
                  'nj-modal-overlay-mobile-list-panel-body',
                  {
                    isGrid:
                      currentModalGalleryType ===
                      ModalGalleryType.IMAGES_VIDEOS,
                    isGeneratedImagesGrid:
                      currentModalGalleryType ===
                      ModalGalleryType.GENERATED_IMAGES,
                  },
                )}
              >
                {currentListOfGalleryItems.map((item, i) => (
                  <div
                    className={classNames(
                      'nj-modal-overlay-list-item-wrapper',
                      {
                        selected: selectedItemIndex === i,
                      },
                    )}
                    key={`${item.url}_${i}`}
                  >
                    {renderGalleryItem(item, i)}
                  </div>
                ))}
              </div>
            </div>
          </MobileModalOverlayList>
        )}
      </div>
    </CSSTransition>
  );
};

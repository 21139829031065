import { PlanFeatureItem } from '../PlanFeatureItem';

export const FreeSection = () => {
  return (
    <>
      <div className="nj-plan-section--feature-list-title">
        10 Tasks per day
      </div>

      <div className="nj-plan-section--feature-items-wrapper extend-hight">
        <PlanFeatureItem title="Ninja Agents" />
        <PlanFeatureItem title="5 minute video chat sessions" />
        <PlanFeatureItem title="Email & In-product support" />
      </div>
    </>
  );
};

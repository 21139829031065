import { CommonNavigationButton } from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons';
import { ChatModeSidePanel } from 'src/types';
import { StackPlus } from '@phosphor-icons/react';
import { useSession } from 'src/hooks';
import { useMemo } from 'react';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';
import {
  AvailableImageGenerationModels,
  ImageGenerationModels,
} from 'src/constants';

export const ExternalModelsButton = () => {
  const handleGTMEventClick = () => {
    sendGTMEvent(GTMEvent.EXTERNAL_MODELS_CLICK);
  };

  const { appUser } = useSession();
  const countOfSelectedModels = useMemo(() => {
    const modelsList = [
      ...(appUser.settings?.external_models?.amazon_bedrock || []),
      ...(appUser.settings?.external_models?.google || []),
      ...(appUser.settings?.external_models?.openai || []),
      ...((appUser?.settings?.image_gen_settings?.models || []).filter(
        (item) =>
          !AvailableImageGenerationModels.includes(
            item.model as ImageGenerationModels,
          ),
      ) || []),
    ];
    const selectedNumber = modelsList.filter((item) => !!item.enabled).length;
    return selectedNumber === 0 ? undefined : selectedNumber;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [appUser.settings?.external_models]);

  return (
    <CommonNavigationButton
      onClick={handleGTMEventClick}
      title="External models"
      sidePanelType={ChatModeSidePanel.EXTERNAL_MODELS}
      IconComponent={StackPlus}
      // disabled={!appUser?.settings?.external_models?.is_capable}
      count={countOfSelectedModels}
    />
  );
};

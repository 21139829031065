import { forwardRef } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {
  Cube,
  Wallet,
  Pulse,
  ArrowLeft,
  UserCircle,
  CirclesThreePlus,
  Gear,
} from '@phosphor-icons/react';
import {
  AppRoutes,
  BillingSettingsNestedRoutes,
  UserSettingsNestedRoutes,
  Navigation,
} from 'src/types';
import { useSession } from 'src/hooks';
import styles from './ManageAccountNavigation.module.scss';
// import { isMacOs } from 'react-device-detect';

const SVG_SIZE_L = 24;
const SVG_SIZE_M = 20;

const SVG_PROPS = {
  size: SVG_SIZE_M,
};

interface ManageAccountNavigationProps {
  onClose?: () => void;
}

export const ManageAccountNavigation = forwardRef<
  HTMLDivElement,
  ManageAccountNavigationProps
>(({ onClose }, ref) => {
  const { pathname } = useLocation();
  const { isProTier, isProTrialTier, isEnterpriseTier } = useSession();

  const navigation: Array<Navigation> = [
    {
      category: 'User Settings',
      links: [
        {
          route: UserSettingsNestedRoutes.PROFILE,
          icon: <UserCircle {...SVG_PROPS} weight="fill" />,
          title: 'Profile',
          defaultRoute: false,
          disabled: false,
          visible: true,
        },
        {
          route: UserSettingsNestedRoutes.INTEGRATIONS,
          icon: <CirclesThreePlus {...SVG_PROPS} />,
          title: 'Integrations',
          defaultRoute: false,
          disabled: false,
          visible: true,
        },
        {
          route: UserSettingsNestedRoutes.GENERAL,
          icon: <Gear {...SVG_PROPS} />,
          title: 'General',
          defaultRoute: false,
          disabled: false,
          visible: true,
        },
        {
          route: UserSettingsNestedRoutes.ACTIVITY,
          icon: <Pulse {...SVG_PROPS} />,
          title: 'Activity',
          defaultRoute: false,
          disabled: !(isProTier || isProTrialTier || isEnterpriseTier),
          visible: true,
        },
      ],
    },
    {
      category: 'Billing Settings',
      links: [
        {
          route: BillingSettingsNestedRoutes.SUBSCRIPTION,
          icon: <Cube {...SVG_PROPS} />,
          title: 'Subscription',
          defaultRoute: true,
          disabled: false,
          visible: true,
        },
        {
          route: BillingSettingsNestedRoutes.BILLING,
          icon: <Wallet {...SVG_PROPS} />,
          title: 'Billing',
          defaultRoute: false,
          disabled: !isProTier,
          visible: true,
        },
      ],
    },
  ];

  // if (isMacOs && isDesktopAndUp) {
  //   const navigationDownload = {
  //     category: 'Download',
  //     links: [
  //       {
  //         route: DownloadNestedRoutes.DOWNLOAD,
  //         icon: <CloudArrowDown {...SVG_PROPS} />,
  //         title: 'Desktop App for macOS',
  //         defaultRoute: false,
  //         disabled: false,
  //         visible: true,
  //       },
  //     ],
  //   };
  //   navigation.push(navigationDownload);
  // }

  // const handleGTMClick = () => {
  //   sendGTMEvent(GTMEvent.DOWNLOAD_DESKTOP_APP);
  //   DownloadApp();
  // };

  return (
    <div className={styles.root} ref={ref}>
      <Link to={AppRoutes.HOME} className={styles.goBackLink}>
        <ArrowLeft size={SVG_SIZE_L} />
        Back to Ninja
      </Link>

      <div className={styles.menu}>
        {navigation.map(({ category, links }) => (
          <div className={styles.navGroup} key={category}>
            <span className={styles.category}>{category}</span>
            <>
              {links.map(
                ({ route, icon, title, defaultRoute, disabled, visible }) => (
                  <div key={title}>
                    {visible && (
                      <NavLink
                        onClick={(e) => {
                          // const download =
                          //   route === DownloadNestedRoutes.DOWNLOAD;

                          // if (disabled || download) {
                          if (disabled) {
                            e.preventDefault();

                            // (olha): temporary disable Download Banner
                            // if (download) {
                            //   handleGTMClick();
                            // }
                          } else if (onClose) {
                            onClose();
                          }
                        }}
                        className={({ isActive }) =>
                          classNames(styles.menuLink, {
                            active:
                              isActive ||
                              (defaultRoute &&
                                pathname === AppRoutes.MANAGE_ACCOUNT),
                            disabled,
                          })
                        }
                        key={route}
                        to={route}
                      >
                        {icon}
                        {title}
                      </NavLink>
                    )}
                  </div>
                ),
              )}
            </>
          </div>
        ))}
      </div>
    </div>
  );
});

import { useMemo, useRef, memo, useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import {
  Message,
  MessageType,
  isResearchTaskCreationCard,
  isChitChatCard,
  isImageGenerationCard,
  ConversationRole,
} from 'src/types';
import { formatHours } from 'src/utils';
import { useTimeZone, useBreakpoint } from 'src/hooks';
import { MessageAuthorPicture } from '../MessageAuthorPicture';
import { MessageAuthorName } from '../MessageAuthorName';
import { SkillStyleToneTags } from '../SkillStyleToneTags';
import { MessageFooter } from '../MessageFooter';
import { ThreadMessageBody } from '../ThreadMessageBody';
import { ThreadMessageActions } from '../ThreadMessageActions';
import classNames from 'classnames';
import { RelatedQuestions } from 'src/components/RelatedQuestions';

interface ThreadMessageProps {
  message: Message;
  canShowProgress?: boolean;
}

// TODO(olha): rename to AgentMessage
const ThreadMessageComponent = ({
  message,
  canShowProgress,
}: ThreadMessageProps) => {
  const [messageToShow, setMessageToShow] = useState(message);

  const throttleMessage = useRef(
    throttle((newMessage: Message) => {
      setMessageToShow(newMessage);
    }, 500),
  );

  useEffect(() => {
    throttleMessage.current(message);
  }, [message]);

  useEffect(() => {
    if (message.is_final_answer) {
      setMessageToShow(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.is_final_answer]);

  const { userTimeZone } = useTimeZone();
  const { isMobile } = useBreakpoint();
  const { timestamp, message_type, payload } = messageToShow;
  // TODO(olha): double-check. looks unnecessary
  const messageRef = useRef<HTMLDivElement>(null);

  const date = useMemo(
    () => formatHours(timestamp, userTimeZone),
    [timestamp, userTimeZone],
  );

  const relatedQuestions = useMemo(() => {
    if (isResearchTaskCreationCard(payload)) {
      return payload?.research_card?.data?.related_questions;
    }

    if (isChitChatCard(payload)) {
      return payload?.data?.related_questions;
    }

    if (isImageGenerationCard(payload)) {
      return payload?.related_questions;
    }
  }, [payload]);

  return (
    <div className="nj-thread-message" ref={messageRef}>
      <div className="nj-thread-message--author-wrapper">
        <div className="nj-thread-message--flex-wrapper">
          <MessageAuthorPicture
            message={messageToShow}
            canShowProgress={canShowProgress}
          />

          {isMobile && (
            <>
              <MessageAuthorName message={messageToShow} />
              <span className="nj-thread-message--date">{date}</span>
            </>
          )}
        </div>

        {isMobile && <ThreadMessageActions message={messageToShow} />}
      </div>

      <div className="nj-thread-message--container">
        {isMobile ? (
          <div className="nj-thread-message--header">
            <SkillStyleToneTags message={messageToShow} />
          </div>
        ) : (
          <div
            className={classNames('nj-thread-message--header', {
              researcher:
                message_type === MessageType.RESEARCH_TASK_CREATION_CARD,
            })}
          >
            <div className="nj-thread-message--flex-wrapper">
              <MessageAuthorName message={messageToShow} />

              <SkillStyleToneTags message={messageToShow} />

              <span className="nj-thread-message--date">{date}</span>
            </div>

            <ThreadMessageActions message={messageToShow} />
          </div>
        )}

        {messageToShow.role === ConversationRole.AGENT &&
          !messageToShow.payload && (
            <p className="nj-thread-message--analizing-request">
              Analyzing request...
            </p>
          )}

        <ThreadMessageBody message={messageToShow} />

        <MessageFooter message={messageToShow} />

        <RelatedQuestions
          data={relatedQuestions}
          defaultExpanded={canShowProgress}
          title={
            isImageGenerationCard(payload)
              ? 'Related Queries'
              : 'Related Questions'
          }
        />
      </div>
    </div>
  );
};

export const ThreadMessage = memo(
  ThreadMessageComponent,
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.message, nextProps.message) &&
      prevProps.canShowProgress === nextProps.canShowProgress
    );
  },
);

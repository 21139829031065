import { createApi } from '@reduxjs/toolkit/query/react';
import { baseJSONQuery } from './config';
import {
  EnvironmentOption,
  VoiceOption,
  VoiceLanguageSampleOption,
  CameraAngleOption,
  AgentOption,
  AvatarOption,
  CommunicationToneOption,
  VideoLinks,
  WelcomeUpsellContent,
} from 'src/types';
import { isProd } from 'src/utils';

export enum JsonTags {
  AgentsAI = 'AgentsAI',
  Avatars = 'Avatars',
  Voices = 'Voices',
  VoiceLanguageSamples = 'VoiceLanguageSamples',
  Environments = 'Environments',
  cameraThumbnails = 'cameraThumbnails',
  CommunicationTones = 'CommunicationTones',
  VideoLinks = 'VideoLinks',
  UpsellModalsConfig = 'UpsellModalsConfig',
}

export const jsonDataApi = createApi({
  reducerPath: 'jsonDataApi',
  tagTypes: [
    JsonTags.AgentsAI,
    JsonTags.Avatars,
    JsonTags.Voices,
    JsonTags.VoiceLanguageSamples,
    JsonTags.Environments,
    JsonTags.cameraThumbnails,
    JsonTags.CommunicationTones,
    JsonTags.VideoLinks,
    JsonTags.UpsellModalsConfig,
  ],
  baseQuery: baseJSONQuery,
  keepUnusedDataFor: 60 * 60 * 10,
  endpoints: (build) => ({
    // TODO(olha): deprecated query and JSON. Don't use it
    getAgentsAIJSON: build.query<AgentOption[], void>({
      query: () => '/agentsAI.json',
      providesTags: () => [JsonTags.AgentsAI],
    }),

    getAvatarsJSON: build.query<AvatarOption[], void>({
      query: () => '/avatars.json',
      providesTags: () => [JsonTags.Avatars],
    }),

    getCommunicationTonesJSON: build.query<CommunicationToneOption[], void>({
      query: () => '/communicationTones.json',
      providesTags: () => [JsonTags.CommunicationTones],
    }),

    getVoicesJSON: build.query<VoiceOption[], void>({
      query: () => '/voices.json',
      providesTags: () => [JsonTags.Voices],
      transformResponse: (response: VoiceOption[]) =>
        [...response].sort((a, b) =>
          a.dispalyVoiceName.localeCompare(b.dispalyVoiceName),
        ),
    }),

    getVoiceLanguageSamplesJSON: build.query<VoiceLanguageSampleOption[], void>(
      {
        query: () => '/voiceLanguageSamples.json',
        providesTags: () => [JsonTags.VoiceLanguageSamples],
      },
    ),

    getEnvironmentsJSON: build.query<EnvironmentOption[], void>({
      query: () => '/environments.json',
      providesTags: () => [JsonTags.Environments],
    }),

    getCameraThumbnailsJSON: build.query<CameraAngleOption[], void>({
      query: () => '/cameraThumbnails.json',
      providesTags: () => [JsonTags.cameraThumbnails],
    }),

    getVideoLinksJSON: build.query<VideoLinks, void>({
      query: () => '/videoLinks.json',
      providesTags: () => [JsonTags.VideoLinks],
    }),

    getUpsellModalsConfig: build.query<WelcomeUpsellContent, void>({
      query: () =>
        isProd()
          ? '/upsellModalsConfigProd.json'
          : '/upsellModalsConfigBeta.json',
      providesTags: () => [JsonTags.UpsellModalsConfig],
    }),
  }),
});

export const {
  useGetVoicesJSONQuery,
  useGetVoiceLanguageSamplesJSONQuery,
  useGetEnvironmentsJSONQuery,
  useGetCameraThumbnailsJSONQuery,
  useGetAgentsAIJSONQuery,
  useGetAvatarsJSONQuery,
  useGetCommunicationTonesJSONQuery,
  useGetVideoLinksJSONQuery,
  useGetUpsellModalsConfigQuery,
} = jsonDataApi;

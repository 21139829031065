/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProgressUpdate } from './ProgressUpdate';
import type { ProgressUpdateV2 } from './ProgressUpdateV2';
import type { ResearchCard } from './ResearchCard';

export type ResearchTaskCreationCard = {
  task_query: string;
  task_hash: string;
  task_id: string;
  progress?: ProgressUpdate;
  progress_v2?: ProgressUpdateV2;
  payload_type?: ResearchTaskCreationCard.payload_type;
  research_card?: ResearchCard;
};

export namespace ResearchTaskCreationCard {
  export enum payload_type {
    RESEARCH_TASK_CREATION_CARD = 'research-task-creation-card',
  }
}

import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Hash } from '@phosphor-icons/react';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import {
  useBreakpoint,
  useCurrentConversation,
  useLeftPanelState,
} from 'src/hooks';
import { AddConversationButton } from 'src/pages/ManageTasksChatPage/components/MultiConversationsListPanel/components/AddConversationButton';
import { MainNavToggleButton } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/MainNavToggleButton';
import { ModelsMenu } from 'src/pages/ManageTasksChatPage/components/ConversationsHeader/components/ModelsMenu';
import { UserMenu } from './components/UserMenu';
import styles from './ConversationsHeader.module.scss';
import { SVG_SIZE_M, ANIMATION_TIMEOUT } from 'src/constants';
import { AvatarSelectorButton } from 'src/pages/ManageTasksChatPage/components/AvatarSelectorButton';
import { CSSTransition } from 'react-transition-group';
import { UnreadCountButton } from 'src/components/UnreadCountButton';
import ForwardRefContext from 'src/contexts/ForwardRefContext';

export const ConversationsHeader = () => {
  const { isMobileOrTablet, isDesktopAndUp } = useBreakpoint();
  const { currentConversation } = useCurrentConversation();
  const { isTasksListExpanded } = useLeftPanelState();

  const { isFullscreen } = useContext(AvatarModeContext);
  const containerRef = useRef<HTMLDivElement>(null);

  const headerTitle = useMemo(
    () => currentConversation?.conversation_hash?.replace('#', '') || '',
    [currentConversation],
  );

  const unreadCount = currentConversation?.number_tasks_require_attention || 0;
  const { threadMessageListRef } = useContext(ForwardRefContext);

  const [offset, setOffset] = useState<number | undefined>(0);

  useEffect(() => {
    const element = threadMessageListRef?.current;
    const onScroll = () => {
      setOffset(element?.scrollTop);
    };

    element?.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      element?.removeEventListener('scroll', onScroll);
    };
  }, [headerTitle, threadMessageListRef]);

  return (
    <header className={styles.root}>
      <div className={styles.actionWrapper}>
        {!isFullscreen && !isTasksListExpanded && <MainNavToggleButton />}

        {!isMobileOrTablet && <AddConversationButton />}
      </div>

      {headerTitle && isMobileOrTablet && (
        <CSSTransition
          in={!!offset}
          timeout={ANIMATION_TIMEOUT}
          classNames="nj-animate-fade"
          unmountOnExit
          nodeRef={containerRef}
        >
          <div ref={containerRef}>
            <div className={styles.title}>
              <div className={styles.textContainer}>
                <div className={styles.center}>
                  <Hash size={SVG_SIZE_M} />
                  <span
                    className={`${unreadCount ? styles.slice : ''} ${styles.text}`}
                  >
                    {headerTitle}
                  </span>
                </div>
                <UnreadCountButton />
              </div>
            </div>
          </div>
        </CSSTransition>
      )}

      {headerTitle && isDesktopAndUp && (
        <h4 className={styles.desktopTitle}>
          <Hash size={SVG_SIZE_M} />
          <span className={styles.center}>{headerTitle}</span>
        </h4>
      )}

      <div className={styles.actionWrapper}>
        <AvatarSelectorButton />
        <ModelsMenu />
        <UserMenu />

        {isMobileOrTablet && (
          <AddConversationButton className="nj-thread-header--mobile-create-new-chat" />
        )}
      </div>
    </header>
  );
};

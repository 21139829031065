import styles from './LLMInfoTable.module.scss';
import { LLMTableItemType } from 'src/types';
import { Icon } from 'src/components/Icon';

type LLMInfoTableProps = {
  llmList: LLMTableItemType[];
  title: string;
};

const SVG_SIZE = 18;

export const LLMInfoTable = ({ llmList, title }: LLMInfoTableProps) => {
  return (
    <div className={styles.root}>
      <table className={styles.table}>
        <thead className={styles.tableHead}>
          <tr className={styles.tableHeadRow}>
            <th className={styles.tableHeadCell}>Model</th>
            <th className={styles.tableHeadCellTitle}>{title}</th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {llmList.map((item) => {
            const LogoIcon = item.icon;
            const amount = new Intl.NumberFormat('en-US').format(
              item.count * 2,
            );
            return (
              <tr className={styles.tableRow} key={item.display_name}>
                <td className={styles.tableCell}>
                  <div className={styles.tableCellContent}>
                    <span>
                      {LogoIcon ? (
                        <LogoIcon size={20} />
                      ) : (
                        <Icon type={item.icon_name} size={SVG_SIZE} />
                      )}
                    </span>
                    <span>{item.display_name}</span>
                  </div>
                </td>
                <td className={styles.tableCellTitle}>{amount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

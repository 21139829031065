import { useBreakpoint, useSession } from 'src/hooks';
import classNames from 'classnames';
import { Warning } from '@phosphor-icons/react';
import { UserTierStatus } from 'src/types/models/UserTierStatus';
import { useGetUserBudgetQuotaInfoQuery } from 'src/store/services';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

const SVG_SIZE = 24;

export const LowCreditsBanner = () => {
  const { appUser, isProTrialTier, isEnterpriseTier, isProTier } = useSession();
  const { onChangeIsVisibleUpdatePlanModal, onToggleVisibleContactNinjaModal } =
    useContext(SessionContext);
  const navigate = useNavigate();

  const {
    data: quotaData,
    isLoading,
    isFetching,
  } = useGetUserBudgetQuotaInfoQuery({
    user_id: appUser.user_id,
  });

  const { isTabletAndUp } = useBreakpoint();

  if (
    appUser?.tier_status !== UserTierStatus.OK ||
    (!!quotaData && !isLoading && !isFetching && !quotaData?.is_low_balance)
  ) {
    return null;
  }

  const handleUpgradeClick = () => {
    navigate(AppRoutes.PAYMENT);
  };

  const handleContactNinjaClick = () => {
    onToggleVisibleContactNinjaModal(true);
  };

  const handleRenewEarlyClick = () => {
    onChangeIsVisibleUpdatePlanModal(true);
  };

  return (
    <div
      className={classNames('nj-common-banner', {
        info: true,
      })}
    >
      <div className="nj-common-banner--left-side-wrapper">
        <Warning size={SVG_SIZE} weight="fill" />
        <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
          <h5 className="nj-common-banner--title">
            {isTabletAndUp ? 'Credits are running low!' : 'Low credits'}
          </h5>
          {isTabletAndUp && !isProTrialTier && !isEnterpriseTier && (
            <p className="nj-common-banner--description ellipsis">
              Renew your subscription early to continue access to Pro features.
            </p>
          )}
        </div>
      </div>
      <div className="nj-common-banner--right-side-wrapper">
        {isEnterpriseTier && (
          <button
            id="Banner-contact-Ninja"
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleContactNinjaClick}
          >
            Contact Ninja
          </button>
        )}
        {isProTrialTier && (
          <button
            id="Banner-upgrade"
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleUpgradeClick}
          >
            Upgrade
          </button>
        )}
        {isProTier && (
          <button
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleRenewEarlyClick}
            disabled={!!appUser?.stripe_info?.renew_early_pending_subscription}
          >
            Add credits
          </button>
        )}
      </div>
    </div>
  );
};

import dayjs from 'dayjs';
import { CheckCircle } from '@phosphor-icons/react';
import {
  externalModelsSet,
  imageGeneratorsModelsSet,
  ninjaLLMComponents,
} from 'src/constants/llmResources';
import { getPriceWithCurrency } from 'src/utils';
import { PaymentsActivityColumn } from 'src/types';
import {
  PAYMENTS_DATE_FORMAT,
  PAYMENTS_DATE_TIME_FORMAT,
  PAYMENTS_TIME_FORMAT,
} from 'src/constants';

export const ACTIVITY_COLUMNS: PaymentsActivityColumn[] = [
  {
    header: 'Task Name',
    accessor: 'task_hash',
    render: (activity) => {
      return (
        <>
          {activity.task_hash}
          <div className="visible-sm hash-timestamp">
            <div>
              {dayjs(activity.timestamp).format(PAYMENTS_DATE_TIME_FORMAT)}
            </div>
          </div>
        </>
      );
    },
  },
  {
    header: 'Models',
    accessor: 'models',
    render: (activity) => {
      const models = activity.models.map((model, index) => {
        const modelKey = [
          model.platform,
          model.provider,
          model.model_name,
        ].join('/');

        return (
          <div key={index} className="activity_models">
            <div className="empty_icon hidden-sm"></div>
            {externalModelsSet[modelKey] &&
              externalModelsSet[modelKey]['display_name']}
            {imageGeneratorsModelsSet[modelKey] &&
              imageGeneratorsModelsSet[modelKey]['display_name']}
          </div>
        );
      });
      const components: React.ReactElement[] = [];
      activity.components.forEach((component, index) => {
        if (ninjaLLMComponents[component.component]) {
          components.push(
            <div key={index} className="activity_models">
              <CheckCircle
                className="hidden-sm"
                size={16}
                weight="fill"
                color="#0A74FF"
                style={{ marginRight: '4px' }}
              />{' '}
              {ninjaLLMComponents[component.component].display_name}
            </div>,
          );
        }
      });
      if (!models.length && !components.length) {
        return ninjaLLMComponents[activity.skill] ? (
          <>
            <CheckCircle
              className="hidden-sm"
              size={16}
              weight="fill"
              color="#0A74FF"
              style={{ marginRight: '4px' }}
            />{' '}
            {ninjaLLMComponents[activity.skill].display_name}
          </>
        ) : null;
      }
      return (
        <>
          {components}
          {models}
        </>
      );
    },
  },
  {
    header: 'Date',
    accessor: 'timestamp',
    render: (activity) => (
      <div>
        <div>{dayjs(activity.timestamp).format(PAYMENTS_TIME_FORMAT)}</div>
        <div>{dayjs(activity.timestamp).format(PAYMENTS_DATE_FORMAT)}</div>
      </div>
    ),
  },
  {
    header: 'Credits',
    accessor: 'cost',
    render: (activity) => {
      const creditInfo =
        activity.status === 'pending'
          ? 'pending...'
          : getPriceWithCurrency(activity.cost);
      return (
        <div>
          <div>{creditInfo}</div>
          {activity.cost === 0 && (
            <div className="included_in_pro">
              <CheckCircle size={16} color="#0A74FF" weight="fill" /> INCLUDED
              IN PRO
            </div>
          )}
        </div>
      );
    },
  },
];

import {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import { Microphone } from '@phosphor-icons/react';
import DebugContext from 'src/contexts/DebugContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import AudioContext from 'src/contexts/AudioContext';
import { logAction } from 'src/utils/analytics';
import { interruptMetahuman, animateMetahuman } from 'src/utils';
import { AvatarAnimations, ChatMode, isMetaHumanSpeechEvent } from 'src/types';
import {
  useSession,
  useMetaHumanEvent,
  useThreads,
  useConversationParams,
  usePrevious,
} from 'src/hooks';
import type { MetaHumanEvent } from 'src/types';
import { Button } from 'src/components/Button';
import { TEMPORARY_CONVERSATION_ID } from 'src/constants';

const SVG_SIZE = 24;

interface MicrophoneTriggerProps {
  startSpeechRecognizing: () => void;
  stopSpeechRecognizing: () => void;
  unMuteMicrophone: () => void;
}

// TODO(olha): needs refactoring, create custom hooks for handling state
export const MicrophoneTrigger = ({
  startSpeechRecognizing,
  stopSpeechRecognizing,
  unMuteMicrophone,
}: MicrophoneTriggerProps) => {
  const {
    appUser: { user_id },
    chatMode,
  } = useSession();
  const { isSubmitHappened } = useThreads();
  const { currentConversationId } = useConversationParams();

  const { debugMode } = useContext(DebugContext);
  const {
    microphoneTriggerRef,
    recordInProgress,
    stopResponseTriggerRef,
    metaHumanTalking,
    setMetaHumanTalking,
  } = useContext(AudioContext);
  const { showReplaceTooltip } = useContext(ThreadInputBoxContext);

  const isAvatarMode = useMemo(() => chatMode === ChatMode.AVATAR, [chatMode]);

  const startRecording = () => {
    logAction('start_recording', {
      type: isAvatarMode ? 'meta_human' : 'chat_form',
    });
    startSpeechRecognizing();
  };

  const stopRecording = (skipInterrupt = false) => {
    if (isAvatarMode && metaHumanTalking && !skipInterrupt) {
      interruptMetahuman(user_id, debugMode);
    }
    if (!skipInterrupt) {
      setMetaHumanTalking(false);
    }
    stopSpeechRecognizing();
  };

  const previousConversationId = usePrevious(currentConversationId);

  useEffect(() => {
    if (
      !currentConversationId.includes(TEMPORARY_CONVERSATION_ID) &&
      !previousConversationId?.includes(TEMPORARY_CONVERSATION_ID)
    ) {
      stopRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversationId, isAvatarMode]);

  const handleToggleAction = () => {
    if (showReplaceTooltip) {
      return;
    }

    if (isAvatarMode) {
      recordInProgress
        ? animateMetahuman(user_id, AvatarAnimations.IDLE, debugMode)
        : animateMetahuman(user_id, AvatarAnimations.LISTENING, debugMode);
    }

    if (isSubmitHappened || metaHumanTalking) {
      // TODO(olha): double-check it
      stopResponseTriggerRef?.current?.stopResponse();

      if (!recordInProgress) {
        startRecording();
      }
      return;
    }

    recordInProgress ? stopRecording() : startRecording();
  };

  /**
   * Handles events from MetaHuman iFrame
   */
  useMetaHumanEvent(
    useCallback(
      (metaHumanEvent: MetaHumanEvent) => {
        if (isMetaHumanSpeechEvent(metaHumanEvent) && metaHumanEvent.silence) {
          // allowing to receive silence event outside of avatar mode
          // to work with stop/mute button correctly
          setMetaHumanTalking(false);

          // work within the avatar mode only on silence event
          if (isAvatarMode) {
            if (!recordInProgress) {
              animateMetahuman(user_id, AvatarAnimations.IDLE, debugMode);
              return;
            }

            animateMetahuman(user_id, AvatarAnimations.LISTENING, debugMode);

            // unmute mic as soon as MetaHuman is done talking.
            unMuteMicrophone();
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [recordInProgress],
    ),
  );

  useImperativeHandle(microphoneTriggerRef, () => ({
    stopRecording,
    onToggleAction: handleToggleAction,
  }));

  const dynamicClasses: string = classNames({
    'recording-on': recordInProgress,
    'limbo-on': metaHumanTalking || showReplaceTooltip,
    hidden: isSubmitHappened,
  });

  return (
    <Button
      aria-label="Click to the action button"
      type="button"
      className={classNames(
        'nj-chat-form--outside-button',
        'nj-microphone-button',
        dynamicClasses,
      )}
      onClick={handleToggleAction}
    >
      <Microphone
        data-testid="microphone-icon"
        size={SVG_SIZE}
        weight={recordInProgress ? 'fill' : 'regular'}
      />
    </Button>
  );
};

import { useContext, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import SessionContext, {
  SessionContextType,
} from 'src/contexts/SessionContext';
import { X } from '@phosphor-icons/react';
import { useSetAllTasksReadInThreadMutation } from 'src/store/services';
import { useSession, useCurrentConversation } from 'src/hooks';
import { ANIMATION_TIMEOUT } from 'src/constants';

const SVG_SIZE = 12;

export const UnreadCountButton = () => {
  const { appUser } = useSession();
  const { currentConversation } = useCurrentConversation();
  const { setCurrentScrollingTaskId } =
    useContext<SessionContextType>(SessionContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const [setAllTasksReadInThread] = useSetAllTasksReadInThreadMutation();

  if (!currentConversation) {
    return null;
  }

  const { number_tasks_require_attention, tasks, conversation_id } =
    currentConversation;

  const unreadCount = number_tasks_require_attention || 0;

  const firstUnreadTask =
    tasks
      ?.slice()
      // TODO(olha): Forced workaround, since in the useThreads tasks are sorted manually for display in the list
      .sort(
        (a, b) =>
          new Date(a.created_at || new Date()).getTime() -
          new Date(b.created_at || new Date()).getTime(),
      )
      .find((item) => !!item.requires_attention) || null;

  const title = `${unreadCount || 1} new message${unreadCount > 1 ? 's' : ''}`;

  const handleReadMessageClick = () => {
    if (!firstUnreadTask?.task_id) {
      return;
    }

    const { task_id } = firstUnreadTask;

    const focusAnchor = document.getElementById(task_id);

    if (focusAnchor) {
      setCurrentScrollingTaskId(task_id);

      focusAnchor?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCloseClick = () => {
    if (!conversation_id) {
      return;
    }
    setAllTasksReadInThread({
      userId: appUser.user_id,
      conversationId: conversation_id,
    });
  };

  return (
    <CSSTransition
      in={!!unreadCount && !!firstUnreadTask}
      timeout={ANIMATION_TIMEOUT}
      classNames="nj-animate-fade"
      unmountOnExit
      nodeRef={containerRef}
    >
      <div ref={containerRef} className="nj-unread-count">
        <div className="nj-unread-count--container">
          <button
            className="nj-unread-count--main-button"
            onClick={handleReadMessageClick}
          >
            {title}
          </button>

          <button
            onClick={handleCloseClick}
            className="nj-unread-count--close-button"
          >
            <X weight="bold" size={SVG_SIZE} />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

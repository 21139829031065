import { useMemo } from 'react';
import { useGetConversationByIdQuery } from 'src/store/services';
import { useAppSelector, sessionState } from 'src/store';
import { useConversationParams } from './useConversationParams';

/**
 * This hook should be used only for base information about the current conversation.
 * For messages and tasks use separate hooks!
 */
export const useCurrentConversationStatuses = () => {
  const {
    appUser: { user_id },
  } = useAppSelector(sessionState);

  const { currentConversationId, isLandingPage } = useConversationParams();

  const shouldSkipGetConversationById = isLandingPage || !user_id;

  const {
    isLoading: isConversationLoading,
    isFetching: isConversationFetching,
    isError: isConversationError,
    error: conversationError,
    isSuccess: isConversationSuccess,
  } = useGetConversationByIdQuery(
    {
      user_id,
      conversation_id: currentConversationId,
    },
    {
      skip: shouldSkipGetConversationById,
      selectFromResult: (result) => {
        return {
          isLoading: result.isLoading,
          isFetching: result.isFetching,
          isError: result.isError,
          error: result.error,
          isSuccess: result.isSuccess,
        };
      },
    },
  );

  return useMemo(
    () => ({
      isCurrentConversationLoading:
        isConversationLoading || isConversationFetching,
      isConversationSuccess,
      isConversationError,
      conversationError,
    }),
    [
      isConversationLoading,
      isConversationFetching,
      isConversationSuccess,
      isConversationError,
      conversationError,
    ],
  );
};

import { useMemo } from 'react';
import { useSession } from 'src/hooks';
import { UserTier } from 'src/types/models/UserTier';
import { LabelPro } from 'src/components/LabelPro';
import { LabelFree } from 'src/components/LabelFree';
import { LabelProTrial } from 'src/components/FlatAppearance/components/LabelProTrial';
import { LabelEnterpriseTrial } from 'src/components/LabelEnterpriseTrial';

export const CurrentPlanLabel = () => {
  const { appUser } = useSession();

  const currentPlan = useMemo(() => {
    switch (appUser?.tier_id) {
      case UserTier.FREE:
        return <LabelFree />;
      case UserTier.PRO_TRIAL:
        return <LabelProTrial />;
      case UserTier.TESTING:
      case UserTier.PAID:
        return <LabelPro />;
      case UserTier.ENTERPRISE_TRIAL:
        return <LabelEnterpriseTrial />;

      default:
        return null;
    }
  }, [appUser]);

  return currentPlan;
};

import classNames from 'classnames';
import type { Icon } from '@phosphor-icons/react';
import {
  useThemeActions,
  useRightPanelState,
  useModeSidePanel,
} from 'src/hooks';
import { ChatModeSidePanel } from 'src/types';
import { SVG_SIZE_M } from 'src/constants';

interface CommonNavigationButtonProps {
  title: string;
  IconComponent?: Icon;
  Component?: JSX.Element;
  sidePanelType?: ChatModeSidePanel;
  isButtonSelected?: boolean;
  withRedDot?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  temporaryIgnoreSelected?: boolean;
  isShowLabel?: boolean;
  elementId?: string;
  dataE2E?: string;
  count?: number;
}

// TODO(olha): component includes legacy functional. Needs refactoring
export const CommonNavigationButton = ({
  title,
  IconComponent,
  Component,
  sidePanelType,
  isButtonSelected,
  withRedDot,
  disabled = false,
  onClick,
  className,
  temporaryIgnoreSelected,
  isShowLabel = true,
  elementId,
  dataE2E,
  count,
}: CommonNavigationButtonProps) => {
  const { chatModeSidePanel } = useModeSidePanel();
  const { toggleSettingsPanelExpanded, updateChatModeSidePanel } =
    useThemeActions();
  const { isSettingsPanelExpanded } = useRightPanelState();

  const isSettingsPanelSelected =
    !temporaryIgnoreSelected &&
    chatModeSidePanel === sidePanelType &&
    isSettingsPanelExpanded;

  const selected = isSettingsPanelSelected || isButtonSelected;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (isSettingsPanelSelected) {
      toggleSettingsPanelExpanded();
      return;
    }

    if (sidePanelType && chatModeSidePanel !== sidePanelType) {
      updateChatModeSidePanel(sidePanelType);
    }

    if (sidePanelType && !isSettingsPanelExpanded) {
      toggleSettingsPanelExpanded();
    }
  };

  return (
    <button
      data-e2e={dataE2E || title || ''}
      onClick={handleClick}
      disabled={disabled}
      className={classNames(
        'nj-section--side-mobile-navigation-button',
        className,
        {
          selected: selected && !disabled,
          disabled: disabled,
        },
      )}
      id={elementId}
    >
      <span className="nj-section--side-navigation-button-mobile-wrapper">
        {(!!IconComponent || !!Component) && (
          <span className="nj-section--side-navigation-button-icon">
            {!!IconComponent && (
              <IconComponent
                size={SVG_SIZE_M}
                weight={selected && !disabled ? 'fill' : 'regular'}
                color="currentColor"
              />
            )}

            {!!Component && Component}

            {withRedDot && !selected && <span className="reddot"></span>}
          </span>
        )}

        {isShowLabel && (
          <span
            className={
              !!IconComponent || !!Component
                ? 'nj-section--side-navigation-title'
                : 'nj-section--side-navigation-section-title'
            }
          >
            {title}
          </span>
        )}

        {count !== undefined && (
          <span className="nj-section--side-navigation-button-count">
            {count}
          </span>
        )}
      </span>
    </button>
  );
};

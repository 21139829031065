import { useCallback, useMemo } from 'react';
import { useSession } from './useSession';
import { getNextUpsellModalTimestamp, getUpsellModalType } from 'src/utils';
import { useGetUpsellModalsConfigQuery } from 'src/store/services';
import { DEFAULT_UPSELL_DELAY } from 'src/constants';
import { useAppDispatch, setRecurringUpsellModalControls } from 'src/store';

export const useRecurringUpsellModal = () => {
  const { appUser } = useSession();
  const dispatch = useAppDispatch();

  const { data } = useGetUpsellModalsConfigQuery();

  const modalType = useMemo(
    () => getUpsellModalType(appUser.tier_id),
    [appUser.tier_id],
  );

  const setNewTimestamp = useCallback(() => {
    const upsellData = data && modalType ? data[modalType] : undefined;

    const delay = upsellData?.duration || DEFAULT_UPSELL_DELAY;

    dispatch(
      setRecurringUpsellModalControls({
        isVisible: false,
        nextTimestamp: getNextUpsellModalTimestamp(delay),
      }),
    );
  }, [modalType, data, dispatch]);

  return {
    setNewUpsellTimestamp: setNewTimestamp,
  };
};

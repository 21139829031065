import Skeleton from 'react-loading-skeleton';
import { useColorTheme } from 'src/hooks';
import styles from './LoadingLabel.module.scss';
import cn from 'classnames';

type LoadingLabelProps = {
  text: string;
  isSelected?: boolean;
  isBiggerShift?: boolean;
};

export const LoadingLabel = ({
  text,
  isSelected = false,
  isBiggerShift = false,
}: LoadingLabelProps) => {
  const { isDarkTheme } = useColorTheme();

  const { darkTheme: baseDark, lightTheme: baseLight } = {
    darkTheme: isSelected ? '#055973' : '#4A5357',
    lightTheme: isSelected ? '#085dcc' : '#61696C',
  };

  const { darkTheme: highlightDark, lightTheme: highlightLight } = {
    darkTheme: isSelected ? '#0AA7D9' : '#8E9496',
    lightTheme: isSelected ? '#ffffff' : '#BBBFC0',
  };

  return (
    <div className={styles.root}>
      <Skeleton
        baseColor={isDarkTheme ? baseDark : baseLight}
        highlightColor={isDarkTheme ? highlightDark : highlightLight}
        duration={2}
        width="100%"
        height="100%"
        count={1}
        className={cn(styles.animatedPart, {
          [styles.biggerShift]: isBiggerShift,
        })}
      />
      <span className={styles.text}>{text}</span>
    </div>
  );
};

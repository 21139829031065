import { deleteUser, signOut } from 'aws-amplify/auth';
import log from 'src/utils/logger';
import { useContext } from 'react';
import AuthContext from 'src/contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useResetAllStoredData } from 'src/hooks';
import { AppRoutes } from 'src/types';

export interface Props {
  logout: () => void;
  logoutWithRedirectToSignUp: () => void;
  deleteCognitoUser: () => Promise<void>;
}

export const useLogout = (): Props => {
  const { setAuthLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const { onResetAllStoredData } = useResetAllStoredData();

  // TODO(olha): double-check if we still need to clear cookies
  const cleanAllCookies = async () => {
    const cookies = document.cookie.split(';');
    cookies.forEach((cookie) => {
      const cookieParts: string[] = cookie.split('=');
      const cookieName: string = cookieParts.shift()?.trim() || '';
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };

  const deleteCognitoUser = async () => {
    try {
      await onResetAllStoredData();
      await cleanAllCookies();
      await deleteUser();
    } catch (error) {
      log.error(error);
    }
  };

  const logout = async () => {
    try {
      setAuthLoading(true);

      await onResetAllStoredData();
      await cleanAllCookies();
      await signOut();
    } catch (error) {
      log.error(error);
      setAuthLoading(false);
    }
  };

  const logoutWithRedirectToSignUp = async () => {
    await logout();
    navigate(AppRoutes.SIGN_UP);
  };

  return {
    logout,
    deleteCognitoUser,
    logoutWithRedirectToSignUp,
  };
};

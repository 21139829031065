import './ActivityTable.scss';
import { useSession } from 'src/hooks';
import { useState, useRef } from 'react';
import { useGetStripeCostsQuery } from 'src/store/services';
import { ArrowUp } from '@phosphor-icons/react';
import { ACTIVITY_COLUMNS } from './activityColumn';

export const ActivityTable = () => {
  const [nextPage, setNextPage] = useState('');
  const { appUser } = useSession();
  const tableRef = useRef<null | HTMLDivElement>(null);

  const { data, isLoading } = useGetStripeCostsQuery({
    user_id: appUser.user_id,
    next_evaluated_key: nextPage,
  });

  const getColumnClass = (colIndex: number) => {
    return colIndex === 1 || colIndex === 2 ? 'light-color' : '';
  };

  const scrollToTop = () => {
    if (!tableRef.current) return;
    tableRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className="nj-activity-table" ref={tableRef}>
        <table>
          <thead>
            <tr>
              {ACTIVITY_COLUMNS.map((col) => (
                <th key={col.accessor} className={col.accessor}>
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
          {data && (
            <tbody>
              {(data?.charged_tasks || []).map((activity, rowIndex) => (
                <tr key={rowIndex}>
                  {ACTIVITY_COLUMNS.map((col, colIndex) => (
                    <td
                      key={`${col.accessor}-${rowIndex}`}
                      className={[getColumnClass(colIndex), col.accessor].join(
                        ' ',
                      )}
                    >
                      {col.render
                        ? col.render(activity)
                        : activity[col.accessor]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
          <tfoot>
            <tr>
              <td colSpan={4}>
                {isLoading ? 'loading...' : !data ? 'No activity found' : ''}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="nj-activity-footer">
        <div>&nbsp;</div>
        <div className="load-more">
          {data?.next_evaluated_key && (
            <button
              onClick={() => setNextPage(data?.next_evaluated_key as string)}
            >
              Load more
            </button>
          )}
        </div>
        <div>
          {(data?.charged_tasks?.length as number) > 15 && (
            <button onClick={scrollToTop}>
              Scroll to top <ArrowUp />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

import { ChatModeSidePanel } from 'src/types';
import { CommonNavigationButton } from '../CommonNavigationButton';
import { HeadCircuit } from '@phosphor-icons/react';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';
import { useSession } from 'src/hooks';
import { useMemo } from 'react';

export const NinjaAgentsButton = () => {
  const { appUser } = useSession();

  const handleGTMEventClick = () => {
    sendGTMEvent(GTMEvent.NINJA_AGENTS_CLICK);
  };

  const countOfSelectedItems = useMemo(() => {
    // todo temporary not count image gen selected models
    // const selectedImageModels = isBeta()
    //   ? (appUser?.settings?.image_gen_settings?.models || []).filter(
    //       (item) =>
    //         AvailableImageGenerationModels.includes(
    //           item.model as ImageGenerationModels,
    //         ) && !!item.enabled,
    //     ).length
    //   : 0;
    const selectedNumber =
      Number(appUser?.settings?.research?.deep_research?.enabled) +
      Number(appUser?.settings?.research?.web_search?.search_images) +
      Number(appUser?.settings?.research?.web_search?.search_videos);
    return selectedNumber === 0 ? undefined : selectedNumber;
  }, [
    appUser?.settings?.research?.deep_research?.enabled,
    appUser?.settings?.research?.web_search?.search_images,
    appUser?.settings?.research?.web_search?.search_videos,
    // appUser?.settings?.image_gen_settings?.models,
  ]);

  return (
    <CommonNavigationButton
      onClick={handleGTMEventClick}
      title="Ninja agents"
      sidePanelType={ChatModeSidePanel.NINJA_AGENTS}
      IconComponent={HeadCircuit}
      // disabled={!appUser?.settings?.research?.is_capable} // todo add new condition which BE will add
      count={countOfSelectedItems}
    />
  );
};

import { WelcomeUpsellModal } from 'src/components/WelcomeUpsellModal';
import { useSession, useRecurringUpsellModal } from 'src/hooks';
import {
  useAppDispatch,
  toggleWelcomeModal,
  useAppSelector,
  modalsControlState,
} from 'src/store';

export const WelcomeModal = () => {
  const dispatch = useAppDispatch();
  const { isVisibleWelcomeModal } = useAppSelector(modalsControlState);

  const { isEnterpriseTier } = useSession();
  const { setNewUpsellTimestamp } = useRecurringUpsellModal();

  const handleCloseModal = () => {
    dispatch(toggleWelcomeModal(false));

    setNewUpsellTimestamp();
  };

  return (
    <WelcomeUpsellModal
      modalType={
        isEnterpriseTier
          ? 'enterprise-tier-welcome-modal'
          : 'pro-trial-welcome-modal'
      }
      isOpen={isVisibleWelcomeModal}
      onClose={handleCloseModal}
    />
  );
};

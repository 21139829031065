import { ContentPane } from 'src/components/ContentPane';
import { useEffect, useState } from 'react';
import { useBreakpoint, useSession, useVisible } from 'src/hooks';
import {
  PLAN_OPTIONS,
  REDIRECT_TO_STRIPE_TIMESTAMP,
  SVG_SIZE_L,
  SVG_SIZE_M,
  SVG_SIZE_XXL,
} from 'src/constants';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes, PlanSectionType } from 'src/types';
import styles from './PaymentPage.module.scss';
import cn from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
import { env } from 'src/env';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import log from 'src/utils/logger';
import { useUpgradeSubscriptionMutation } from 'src/store/services';
import { Spinner } from 'src/components/Loading';
import { ArrowLeft, CaretLeft } from '@phosphor-icons/react';
import { PlanSection } from 'src/components/PlanCardModal/components/PlanSection';

export const PaymentPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile, isMobileOrTablet } = useBreakpoint();
  const defaultPlan =
    PLAN_OPTIONS.find((item) => item.isDefault) || PLAN_OPTIONS[0];

  const PlanSections: [PlanSectionType, PlanSectionType] = isMobileOrTablet
    ? ['pro-section', 'free-section']
    : ['free-section', 'pro-section'];

  const {
    isVisible: isMobileEmbeddedCheckoutVisible,
    handleVisibilitySet: onOpenMobileEmbeddedCheckoutVisible,
    handleVisibilityRemove: onCloseMobileEmbeddedCheckoutVisible,
  } = useVisible();

  const [clientSecret, setClientSecret] = useState<null | string>(null);
  const { isOpenTier, appUser, isProTier, isEnterpriseTier } = useSession();
  const stripePromise = loadStripe(env.REACT_APP_STRIPE_PK);
  const navigate = useNavigate();
  const [upgradeSubscription] = useUpgradeSubscriptionMutation();

  const initiateStripeRequest = async ({
    lookup_key,
    promotion_code,
  }: {
    lookup_key: string;
    promotion_code?: string;
  }) => {
    try {
      const result = await upgradeSubscription({
        lookup_key: lookup_key,
        user_id: appUser.user_id,
        promotion_code: promotion_code,
        embedded: true,
      }).unwrap();

      if (!!result?.checkout_session?.client_secret) {
        setClientSecret(result.checkout_session.client_secret);
      }
    } catch (error) {
      log.error(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (appUser.user_id && isOpenTier) {
      localStorage.setItem(
        REDIRECT_TO_STRIPE_TIMESTAMP,
        dayjs().valueOf().toString(),
      );
      navigate(AppRoutes.LOGIN);
    } else if (appUser.user_id && !isOpenTier) {
      if (!isProTier && !isEnterpriseTier) {
        initiateStripeRequest({
          lookup_key: defaultPlan.lookup_key,
          promotion_code: defaultPlan.promotion_code,
        });
      } else {
        navigate('/');
      }
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenTier, appUser.user_id, isProTier, isEnterpriseTier]);

  const handleRemoveFlagFromLS = () => {
    if (!!localStorage.getItem(REDIRECT_TO_STRIPE_TIMESTAMP)) {
      localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
    }
  };

  const isShowPlanPart =
    !isMobile || (isMobile && !isMobileEmbeddedCheckoutVisible);

  return isLoading ? (
    <ContentPane>
      <div className={styles.loadingOverlay}>
        <Spinner size={SVG_SIZE_XXL} inline />
        <span className={styles.text}>Processing payment…</span>
      </div>
    </ContentPane>
  ) : (
    <div className={cn(styles.root, isShowPlanPart ? 'nj-beta' : '')}>
      {isShowPlanPart && (
        <div className={styles.centerPaymentPart}>
          <Link
            to={AppRoutes.HOME}
            className={styles.goBackLink}
            onClick={handleRemoveFlagFromLS}
          >
            <ArrowLeft size={SVG_SIZE_L} />
            Back to Ninja
          </Link>
          <div className={styles.planWrapper}>
            {PlanSections.map((item) => (
              <PlanSection
                key={item}
                type={item}
                onOpenMobileCheckout={onOpenMobileEmbeddedCheckoutVisible}
              />
            ))}
          </div>
        </div>
      )}
      {(!isMobile || (isMobile && isMobileEmbeddedCheckoutVisible)) && (
        <div className={styles.embeddedPaymentWrapper}>
          {isMobile && !clientSecret && (
            <div className={styles.mobileBackButtonRow}>
              <button
                type="button"
                onClick={onCloseMobileEmbeddedCheckoutVisible}
                className={styles.mobileBackButton}
              >
                <CaretLeft size={SVG_SIZE_M} />
                <span>Checkout</span>
              </button>
            </div>
          )}
          <div className={styles.loadingContainer}>
            <div>
              <div className={styles.loading}>
                {/* TODO(olha): create a loading component */}
                <span className="nj-message-author-picture--spinner" />
              </div>
              <span className={styles.loadingTitle}>
                Loading payment options…
              </span>
            </div>
          </div>
          {!!clientSecret && (
            <div className={styles.embeddedStripeWrapper}>
              {isMobile && (
                <div className={styles.mobileBackButtonRow}>
                  <button
                    type="button"
                    onClick={onCloseMobileEmbeddedCheckoutVisible}
                    className={styles.mobileBackButton}
                  >
                    <CaretLeft size={SVG_SIZE_M} />
                    <span>Checkout</span>
                  </button>
                </div>
              )}

              <EmbeddedCheckoutProvider
                key={clientSecret}
                stripe={stripePromise}
                options={{
                  clientSecret,
                  onComplete: () => {
                    navigate(AppRoutes.HOME);
                    handleRemoveFlagFromLS();
                  },
                }}
              >
                <EmbeddedCheckout className={styles.embeddedStripe} />
              </EmbeddedCheckoutProvider>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

import {
  NinjaSearchTypes,
  WebSearchTypes,
  WebSearchUserSettings,
} from 'src/types';
import {
  AvailableImageGenerationModels,
  ImageGenerationModels,
  imageModelOptions,
  imageNumberOptions,
  imageStyleOptions,
  webSearchTypesList,
} from 'src/constants/llmResources';
import { Checkbox } from 'src/components/Checkbox';
import { LabelPro } from 'src/components/LabelPro';
import { LabelInsufficientCredits } from 'src/components/LabelInsufficientCredits';
import { FormProvider } from 'src/components/FormProvider';
import { useSession, useUserData } from 'src/hooks';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import { useForm } from 'react-hook-form';
import log from 'src/utils/logger';
import {
  ListMagnifyingGlass,
  Images,
  Code,
  ChatCircleText,
  Calendar,
  ArrowSquareOut,
} from '@phosphor-icons/react';
import { AtomIcon } from 'src/images/icons/atom';
import { sendGTMEvent } from 'src/utils';
import { GTMEvent } from 'src/types';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { Link } from 'react-router-dom';

import { SVG_SIZE_M, SVG_SIZE_L } from 'src/constants';
import styles from './NinjaAgentsSection.module.scss';
import { Select } from 'src/components/Select';
import {
  DEEP_RESEARCHER,
  IMAGE_GEN,
  CODER,
  ADVISOR,
  SCHEDULER,
} from 'src/constants/externalLinks';
import { ImageGenStyles } from 'src/types/models/ImageGenStyles';
import type { ImageGenSettings } from 'src/types/models/ImageGenSettings';

enum ImageGenTypes {
  IMAGE_MODEL = 'IMAGE_MODEL',
  IMAGE_NUMBER = 'IMAGE_NUMBER',
}

interface FormData {
  [NinjaSearchTypes.DEEP_RESEARCH]: boolean;
  [WebSearchTypes.IMAGE_SEARCH]: boolean;
  [WebSearchTypes.VIDEO_SEARCH]: boolean;
  [ImageGenTypes.IMAGE_MODEL]?: string;
  [ImageGenTypes.IMAGE_NUMBER]?: number;
}

export const NinjaAgentsSection = () => {
  type Options = {
    value: ImageGenStyles;
    label: string;
  };

  const MENU_HEIGHT = 410;

  const {
    appUser: { settings, user_id },
    isFreeTier,
    isProTier,
    isProTrialTier,
    isEnterpriseTier,
    isOpenTier,
  } = useSession();

  const styleValue = settings?.image_gen_settings?.style?.value || 'none';
  const styleSelected = imageStyleOptions.find(
    (option) => option.value === styleValue,
  );

  const { updateUserSettings } = useUserData();

  const [trigger] = useLazyGetUserByIdQuery();

  const methods = useForm<FormData>({
    defaultValues: async () => {
      const result = await trigger(user_id, true);
      const { data } = result;
      const pixModel = (data?.settings?.image_gen_settings?.models || []).find(
        (item) => item.model === ImageGenerationModels.PIX,
      );
      const pixProModel = (
        data?.settings?.image_gen_settings?.models || []
      ).find((item) => item.model === ImageGenerationModels.PIX_PRO);

      const currentImgModel =
        pixModel?.enabled && pixProModel?.enabled
          ? 'both'
          : pixModel?.enabled
            ? pixModel?.model || ''
            : pixProModel?.model || '';

      const currentImgNumber =
        (pixModel?.enabled ? pixModel?.num_images : pixProModel?.num_images) ||
        0;

      return {
        [NinjaSearchTypes.DEEP_RESEARCH]:
          data?.settings?.research?.deep_research?.enabled || false,
        [WebSearchTypes.IMAGE_SEARCH]:
          data?.settings?.research?.web_search?.search_images || false,
        [WebSearchTypes.VIDEO_SEARCH]:
          data?.settings?.research?.web_search?.search_videos || false,
        [ImageGenTypes.IMAGE_MODEL]: currentImgModel,
        [ImageGenTypes.IMAGE_NUMBER]: currentImgNumber,
      };
    },
  });

  const { setValue, getValues, reset } = methods;

  const handleWebSearchSwitcher = async (
    checked: boolean,
    value: keyof WebSearchUserSettings | keyof WebSearchTypes,
  ) => {
    const research = {
      research: {
        ...(settings?.research || {}),
        web_search: {
          ...(settings?.research?.web_search || {}),
          [value]: checked,
        },
      },
    };

    try {
      await updateUserSettings(research);
    } catch (e) {
      setValue(
        value as WebSearchTypes.IMAGE_SEARCH | WebSearchTypes.VIDEO_SEARCH,
        !checked,
      );
      log.error(e);
    }
  };

  const handleDeepResearchSwitcher = async (checked: boolean) => {
    const research = {
      research: {
        ...(settings?.research || {}),
        deep_research: {
          ...settings?.research?.deep_research,
          enabled: checked,
        },
      },
    };

    try {
      await updateUserSettings(research);
    } catch (e) {
      setValue(NinjaSearchTypes.DEEP_RESEARCH, !checked);
      log.error(e);
    }
  };

  const handleGTMEventClick = () => {
    sendGTMEvent(GTMEvent.NINJA_DEEP_RESEARCH_CLICK);
  };

  const researcherContent = (
    <>
      <div className="nj-web-research-content-form-wrapper">
        {webSearchTypesList.map(({ value, label }) => {
          return (
            <div
              key={value}
              className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper"
            >
              <Checkbox
                label={
                  <p className="nj-section--field-title">
                    <span>{label}</span>
                  </p>
                }
                name={value}
                onChangeHandler={(checked: boolean) =>
                  handleWebSearchSwitcher(
                    checked,
                    value as keyof WebSearchUserSettings,
                  )
                }
              />
            </div>
          );
        })}

        <div>
          <div className="nj-section--field-wrapper nj-web-research-deep-research-wrapper">
            <div className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper">
              <Checkbox
                label={
                  <div className="nj-section--field-title">
                    <div className="nj-section--field-title-wrapper">
                      <div className="nj-section--field-title-wrapper-row">
                        <span className="nj-section--field-title-text">
                          Ninja Deep Research
                        </span>
                        <div className="nj-section--field-icon-wrapper">
                          <AtomIcon />
                        </div>
                        {isFreeTier &&
                          !settings?.research?.deep_research?.is_capable && (
                            <LabelPro />
                          )}
                        {(isProTier || isProTrialTier || isEnterpriseTier) &&
                          !settings?.research?.deep_research?.is_capable && (
                            <LabelInsufficientCredits />
                          )}
                      </div>
                    </div>
                  </div>
                }
                disabled={!settings?.research?.deep_research?.is_capable}
                name={NinjaSearchTypes.DEEP_RESEARCH}
                onChangeHandler={(checked: boolean) => {
                  handleDeepResearchSwitcher(checked);
                  handleGTMEventClick();
                }}
              />
            </div>
          </div>
        </div>

        <Link to={DEEP_RESEARCHER} target="_blank" className={styles.link}>
          Learn more <ArrowSquareOut size={SVG_SIZE_M} />
        </Link>
      </div>
    </>
  );

  const handleImageStyleSelect = async (newValue: Options | null) => {
    const { value } = newValue as Options;

    const image_gen_settings = {
      image_gen_settings: {
        ...(settings?.image_gen_settings || {}),
        style: {
          value,
        },
      },
    };

    try {
      await updateUserSettings(image_gen_settings);
    } catch (e) {
      // No need to reset the values in case we fall into an exception, UI is handling gracefully
      log.error(e);
    }
  };

  const handleImageModelSelect = async (
    newValue: {
      label: string;
      value: string;
    },
    name: string,
  ) => {
    const { value } = newValue;

    const updatedModels = (settings?.image_gen_settings?.models || []).map(
      (item) => {
        const currentNumValue = getValues(ImageGenTypes.IMAGE_NUMBER) || 1;
        if (item.model === ImageGenerationModels.PIX) {
          const isEnabled =
            value === ImageGenerationModels.PIX || value === 'both';
          return {
            ...item,
            enabled: isEnabled,
            num_images: isEnabled ? currentNumValue : item.num_images,
          };
        }

        if (item.model === ImageGenerationModels.PIX_PRO) {
          const isEnabled =
            value === ImageGenerationModels.PIX_PRO || value === 'both';
          return {
            ...item,
            enabled: isEnabled,
            num_images: isEnabled ? currentNumValue : item.num_images,
          };
        }

        if (item.model === value) {
          return { ...item, enabled: true, num_images: currentNumValue };
        }
        return item;
      },
    );

    const image_gen_settings = {
      image_gen_settings: {
        ...(settings?.image_gen_settings || {}),
        models: updatedModels,
      } as ImageGenSettings,
    };

    try {
      await updateUserSettings(image_gen_settings);
      setValue(ImageGenTypes.IMAGE_MODEL, value);
    } catch (e) {
      // No need to reset the values in case we fall into an exception, UI is handling gracefully
      log.error(e);
    }
  };

  const handleImageNumberSelect = async (newValue: {
    label: string;
    value: number;
  }) => {
    const { value } = newValue;

    const updatedModels = (settings?.image_gen_settings?.models || []).map(
      (item) => {
        const currentImageModel = getValues(ImageGenTypes.IMAGE_MODEL);
        if (
          item.model === currentImageModel ||
          (currentImageModel === 'both' &&
            AvailableImageGenerationModels.includes(
              item.model as ImageGenerationModels,
            ))
        ) {
          return { ...item, num_images: value };
        }
        return item;
      },
    );

    const image_gen_settings = {
      image_gen_settings: {
        ...(settings?.image_gen_settings || {}),
        models: updatedModels,
      },
    };

    try {
      await updateUserSettings(image_gen_settings);
      setValue(ImageGenTypes.IMAGE_NUMBER, value);
    } catch (e) {
      // No need to reset the values in case we fall into an exception, UI is handling gracefully
      log.error(e);
    }
  };

  const imageGeneratorContent = (
    <div>
      <p className={styles.description}>
        Ninja’s proprietary model trained using PixArt-Sigma as a base.
      </p>
      <label className={styles.label}>Image Style</label>
      <Select
        aria-label="image-style"
        name="imageStyle"
        options={imageStyleOptions}
        className={styles.select}
        maxMenuHeight={MENU_HEIGHT}
        value={styleSelected}
        onChange={handleImageStyleSelect}
        isDisabled={isOpenTier}
      />
      <label className={styles.label}>Ninja Image Model</label>
      <Select
        aria-label="image-model"
        name={ImageGenTypes.IMAGE_MODEL}
        options={imageModelOptions}
        className={styles.select}
        onChange={handleImageModelSelect}
        isDisabled={isOpenTier}
      />
      <label className={styles.label}>Images Per Model</label>
      <Select
        aria-label="image-number"
        name={ImageGenTypes.IMAGE_NUMBER}
        options={imageNumberOptions}
        className={styles.select}
        onChange={handleImageNumberSelect}
        isDisabled={isOpenTier}
      />
      <Link to={IMAGE_GEN} target="_blank" className={styles.link}>
        Learn more <ArrowSquareOut size={SVG_SIZE_M} />
      </Link>
    </div>
  );

  const deepResearch = settings?.research?.deep_research?.enabled ? 1 : 0;
  const images = settings?.research?.web_search?.search_images ? 1 : 0;
  const videos = settings?.research?.web_search?.search_videos ? 1 : 0;

  const selectedResearcher = deepResearch + images + videos;
  const selectedImageGenerator = 0;

  const agents = [
    {
      id: 'image-generator',
      title: 'Image Generator',
      selection: false,
      icon: <Images size={SVG_SIZE_L} />,
      content: imageGeneratorContent,
      selected: selectedImageGenerator,
      expanded: true,
      style: true,
    },
    {
      id: 'researcher',
      title: 'Researcher',
      selection: true,
      icon: <ListMagnifyingGlass size={SVG_SIZE_L} />,
      content: researcherContent,
      selected: selectedResearcher,
      expanded: true,
      style: false,
    },
    {
      id: 'coder',
      title: 'Coder',
      selection: false,
      icon: <Code size={SVG_SIZE_L} />,
      content: (
        <Link to={CODER} target="_blank" className={styles.link}>
          Learn more <ArrowSquareOut size={SVG_SIZE_M} />
        </Link>
      ),
      expanded: false,
      style: false,
    },
    {
      id: 'advisor',
      title: 'Advisor',
      selection: false,
      icon: <ChatCircleText size={SVG_SIZE_L} />,
      content: (
        <Link to={ADVISOR} target="_blank" className={styles.link}>
          Learn more <ArrowSquareOut size={SVG_SIZE_M} />
        </Link>
      ),
      expanded: false,
      style: false,
    },
    {
      id: 'scheduler',
      title: 'Scheduler',
      selection: false,
      icon: <Calendar size={SVG_SIZE_L} />,
      content: (
        <Link to={SCHEDULER} target="_blank" className={styles.link}>
          Learn more <ArrowSquareOut size={SVG_SIZE_M} />
        </Link>
      ),
      expanded: false,
      style: false,
    },
  ];

  const handleClear = async () => {
    const research = {
      research: {
        ...(settings?.research || {}),
        web_search: {
          ...(settings?.research?.web_search || {}),
          [WebSearchTypes.IMAGE_SEARCH]: false,
          [WebSearchTypes.VIDEO_SEARCH]: false,
        },
        deep_research: {
          ...settings?.research?.deep_research,
          enabled: false,
        },
      },
    };

    try {
      await updateUserSettings(research);

      const allValues = getValues();
      const resetValues = Object.fromEntries(
        Object.keys(allValues).map((key) => {
          if (
            key === ImageGenTypes.IMAGE_MODEL ||
            key === ImageGenTypes.IMAGE_NUMBER
          ) {
            return [key, allValues[key]];
          }
          return [key, false];
        }),
      );

      reset(resetValues);
    } catch (e) {
      log.error(e);
    }
  };

  const calculatedFields = getValues();
  delete calculatedFields.IMAGE_MODEL;
  delete calculatedFields.IMAGE_NUMBER;

  const modelsList = Object.values(calculatedFields).every((item) => !item);
  const isClearDisabled = modelsList;

  return (
    <div className="nj-section--main-container with-padding nj-ninja-agents-section-wrapper">
      <h6 className="nj-section--main-container-subtitle-secondary">
        Ninja's models are proprietary, agentic, and fine-tuned, utilizing the
        advanced capabilities of Llama 3.1 405B
      </h6>
      <div>
        <FormProvider<FormData> methods={methods}>
          <div className="nj-researcher-form-wrapper">
            <div className="nj-accordion nj-accordion-external-models">
              {agents.map(
                ({
                  id,
                  title,
                  selection,
                  icon,
                  content,
                  selected,
                  expanded,
                  style,
                }) => {
                  let rightSideComponent = null;

                  if (selection) {
                    rightSideComponent = (
                      <span className="nj-accordion--label-selected-items">
                        {selected} selected
                      </span>
                    );
                  }

                  if (style) {
                    rightSideComponent = (
                      <span className="nj-accordion--label-selected-items">
                        {styleSelected?.label}
                      </span>
                    );
                  }

                  const header = (
                    <div className="nj-accordion--label-content-wrapper">
                      {icon}
                      <span className="nj-accordion--label-content-text">
                        {title}
                      </span>
                    </div>
                  );

                  const contentWrapper = (
                    <div className="nj-accordion-external-models-content-wrapper">
                      <div className="nj-accordion-external-models-checkboxes-wrapper">
                        {content}
                      </div>
                    </div>
                  );

                  return (
                    <CollapsedItem
                      key={id}
                      header={header}
                      title={'Ninja Agent'}
                      content={contentWrapper}
                      isExpanded={expanded}
                      rightSideComponent={rightSideComponent}
                    />
                  );
                },
              )}
            </div>
          </div>
        </FormProvider>
        <button
          className="nj-external-models-clear-button"
          type="button"
          disabled={isClearDisabled}
          onClick={handleClear}
        >
          Clear all
        </button>
      </div>
    </div>
  );
};

import styles from './ContactNinjaModal.module.scss';
import { Modal } from 'src/components/Modal';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

export const ContactNinjaModal = () => {
  const { isOpenContactNinjaModal, onToggleVisibleContactNinjaModal } =
    useContext(SessionContext);

  const handleCloseModal = () => {
    onToggleVisibleContactNinjaModal(false);
  };

  return (
    <Modal
      open={isOpenContactNinjaModal}
      onClose={handleCloseModal}
      className={styles.contactNinjaModal}
      overlayClassName={styles.overlay}
      blockScroll={false}
    >
      <div className={styles.root}>
        <h4 className={styles.title}>Update subscription</h4>
        <span className={styles.text}>
          Please contact Ninja at{' '}
          <a className={styles.link} href="mailto:support@ninjatech.ai">
            support@ninjatech.ai
          </a>{' '}
          to update your Ninja Enterprise Trial subscription.
        </span>
        <div className={styles.buttonRow}>
          <button
            type="button"
            onClick={handleCloseModal}
            className={styles.button}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

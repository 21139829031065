import { CheckCircle } from '@phosphor-icons/react';
import { PlanFeatureItem } from '../PlanFeatureItem';
import {
  PRO_MAIN_FEATURES,
  PRO_ADDITIONAL_FEATURES,
  SVG_SIZE_S,
} from 'src/constants';
import { ExternalModelsList } from '../ExternalModelsList';
import { useVisible } from 'src/hooks';
import { PayPerUseModal } from 'src/components/PlanCardModal/components/PayPerUseModal';

export const ProSection = () => {
  const {
    isVisible: isShowPayPerUseModal,
    handleVisibilitySet: handleOpenPayPerUseModal,
    handleVisibilityRemove: handleClosePayPerUseModal,
  } = useVisible();

  return (
    <>
      <div className="nj-plan-section--feature-items-wrapper">
        <div className="nj-plan-section--feature-list-title tasks-wrapper no-padding">
          <span className="nj-plan-section--feature-list-main-title">
            Unlimited tasks
          </span>
          <span className="nj-plan-section--feature-list-title">
            <span className="nj-plan-section--icon-wrapper">
              <CheckCircle weight="fill" size={SVG_SIZE_S} />
            </span>
            <span className="nj-plan-section--feature-list-sub-title">
              included
            </span>
          </span>
        </div>

        {PRO_MAIN_FEATURES.map((item) => (
          <PlanFeatureItem key={item.title} title={item.title} isSecondary />
        ))}

        <hr className="divider" />

        <div className="nj-plan-section--feature-list-subtitle">
          <span>24+ External LLMs</span>

          <button
            type="button"
            onClick={handleOpenPayPerUseModal}
            className="nj-plan-section--highlight-link"
          >
            Pay per use
          </button>
        </div>

        <PlanFeatureItem
          title="1000+ text & image tasks with external AI models"
          isSecondary
        />
        <div />

        <ExternalModelsList />

        <div className="nj-plan-section--feature-list-subtitle">
          Featuring with
        </div>
        {PRO_ADDITIONAL_FEATURES.map((item) => (
          <PlanFeatureItem key={item.title} title={item.title} isSecondary />
        ))}
      </div>
      <PayPerUseModal
        isOpen={isShowPayPerUseModal}
        onClose={handleClosePayPerUseModal}
      />
    </>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface ModalsControl {
  isVisibleWelcomeModal: boolean;
}

const initialState: ModalsControl = {
  isVisibleWelcomeModal: false,
};

export const modalsControlSlice = createSlice({
  name: 'modalsControl',
  initialState,
  reducers: {
    toggleWelcomeModal: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isVisibleWelcomeModal = payload ?? !state.isVisibleWelcomeModal;
    },
  },
});

export const modalsControlState = (state: RootState) =>
  state.modalsControl as ModalsControl;

export const { toggleWelcomeModal } = modalsControlSlice.actions;

export default modalsControlSlice.reducer;

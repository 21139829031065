import { useEffect, useRef } from 'react';
import { AppRoutes, GTMEvent } from 'src/types';
import { sendGTMEvent } from 'src/utils';
import { useAppDispatch, toggleWelcomeModal } from 'src/store';
import { useSession } from './useSession';
import { useUserData } from './useUserData';
import { REDIRECT_TO_STRIPE_TIMESTAMP } from 'src/constants';
import { useNavigate } from 'react-router-dom';

export const useTermsServiceAgreement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { appUser, isFreeTier, isProTrialTier, isEnterpriseTier } =
    useSession();

  const { updateUserData } = useUserData();

  // (olha): it's an important flag to avoid multiple calls to the GTM
  const sendedEventCounter = useRef<number>(0);

  const handleConsentUpdate = async () => {
    if (sendedEventCounter.current === 0) {
      sendedEventCounter.current += 1;

      const hasRedirectToStripe = localStorage.getItem(
        REDIRECT_TO_STRIPE_TIMESTAMP,
      );

      await updateUserData(
        {
          consented: true,
        },
        false,
      );

      sendGTMEvent(GTMEvent.CONSENT_FORM_CONFIRM);

      if (hasRedirectToStripe) {
        return;
      }

      if (isProTrialTier || isEnterpriseTier) {
        dispatch(toggleWelcomeModal(true));
      }

      if (isFreeTier) {
        navigate(AppRoutes.PAYMENT);
      }
    }
  };

  useEffect(() => {
    if (!!appUser?.user_id && appUser?.consented === false) {
      handleConsentUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser?.user_id, appUser?.consented]);
};

import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { AnthropicLogo } from 'src/images/logos/anthropic-logo';
import { Icon } from 'src/components/Icon';

export const UPGRADE_MODAL_FEATURES = [
  { title: 'Image Generation & Editing' },
  { title: 'Professional & Creative Writing' },
  { title: 'Researching' },
  { title: 'Coding' },
  { title: 'Scheduling meetings' },
];

export const EXTERNAL_MODELS_LIST = [
  {
    title: 'OpenAI GPT-4o',
    icon: <ChatGPTLogo />,
  },
  {
    title: 'Anthropic Claude 3.5 Sonnet',
    icon: <AnthropicLogo />,
  },
  {
    title: 'Google Gemini 1.5 Pro',
    icon: <Icon type="googleColorShortLogo" size={18} />,
  },
  {
    title: 'Amazon Titan Image Generator',
    icon: <Icon type="awsLogo" size={18} />,
  },
  {
    title: 'Stable Diffusion XL 1.0',
    icon: <Icon type="bedrockStableLogo" size={18} />,
  },
];

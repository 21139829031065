import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/FormProvider';
import { useSession, useSettings, useColorTheme } from 'src/hooks';
import { getFullName } from 'src/utils';
import { SettingsTabs } from 'src/store/slices/settingsSlice';
import {
  CommonNavigationButton,
  SignOutButton,
} from 'src/pages/ManageTasksChatPage/components/SidePanel/components/SideNavigationButtons';
import { ManagePlanLink } from './components/ManagePlanLink';
import { UserImage } from 'src/components/UserImage';

const PROFILE_IMAGE_SIZE = 64;

enum Theme {
  Light = 'light',
  Dark = 'dark',
}

interface ProfileInfoFormData {
  theme: Theme;
}

// TODO(olha): will be deprecated soon
export const ProfileInfo = () => {
  const { changeSelectedSettingsTab } = useSettings();
  const { appUser } = useSession();
  const { isDarkTheme } = useColorTheme();

  const userName = getFullName(appUser);

  const methods = useForm<ProfileInfoFormData>({
    defaultValues: {
      theme: isDarkTheme ? Theme.Dark : Theme.Light,
    },
  });

  const handleClick = (tab: SettingsTabs) => {
    changeSelectedSettingsTab(tab);
  };

  return (
    <FormProvider<ProfileInfoFormData> methods={methods}>
      <div className="nj-section--main-container with-padding nj-section--profile-info">
        <div className="nj-section--profile-info-wrapper">
          <span className="nj-section--profile-info-icon">
            <UserImage size={PROFILE_IMAGE_SIZE} />
          </span>

          <div>
            <p className="nj-section--profile-info-text-field">{userName}</p>
            <p className="nj-section--profile-info-text-field description">
              {appUser.email}
            </p>
          </div>
        </div>

        <hr className="nj-section--side-settings-panel-divider" />

        <h3 className="nj-section--profile-info-title">Settings</h3>

        {Object.values(SettingsTabs).map((item) => (
          <CommonNavigationButton
            title={item}
            onClick={() => handleClick(item)}
            key={item}
            dataE2E={
              item === SettingsTabs.ACCESS_AND_DATA
                ? 'access-data-button'
                : item
            }
          />
        ))}

        <hr className="nj-section--side-settings-panel-divider" />

        <ManagePlanLink />

        <hr className="nj-section--side-settings-panel-divider" />
      </div>

      <SignOutButton />
    </FormProvider>
  );
};

import { useRef, useContext, useMemo } from 'react';
import styles from './Tooltip.module.scss';
import { XCircle } from '@phosphor-icons/react';
import { useLeftPanelState, useBreakpoint, useSession } from 'src/hooks';
import { useVisible, useConversationParams } from 'src/hooks';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_TIMEOUT, PRICE_PER_MONTH } from 'src/constants';
import AvatarModeContext from 'src/contexts/AvatarModeContext';
import { UserTier } from 'src/types/models/UserTier';
import { LabelPro } from 'src/components/LabelPro';

interface TooltipProps {
  children: React.ReactNode;
}

// TODO(olha): Needs refactoring to avoid code duplicate from TiersAuthFooter
export const Tooltip = ({ children }: TooltipProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const SVG_SIZE = 24;
  const { isVisible, handleVisibilityRemove } = useVisible(true);

  const { appUser, isProTrialTier, isOpenTier } = useSession();
  const { isTasksListExpanded } = useLeftPanelState();
  const { isLandingPage } = useConversationParams();

  const { isMobile } = useBreakpoint();
  const { isFullscreen } = useContext(AvatarModeContext);

  const handleClick = () => {
    handleVisibilityRemove();
  };

  const display =
    isLandingPage && !isTasksListExpanded && isVisible && !isFullscreen;

  const title = useMemo(() => {
    switch (appUser?.tier_id) {
      case UserTier.OPEN:
        return 'Ninja Pro Trial for free';
      case UserTier.FREE:
      case UserTier.PRO_TRIAL:
        return `Upgrade for only $${PRICE_PER_MONTH}/mo`;
      case UserTier.TESTING:
      case UserTier.PAID:
      case UserTier.PRO_ZERO:
        return <LabelPro />;

      default:
        return '';
    }
  }, [appUser]);

  const description = useMemo(() => {
    switch (appUser?.tier_id) {
      case UserTier.OPEN:
        return 'Enjoy 7 days of unlimited access to Ninja Agents. No credit card needed.';
      case UserTier.FREE:
      case UserTier.PRO_TRIAL:
        return 'Gain full access to 24+ industry leading LLMs and unlimited Ninja AI agents.';
      case UserTier.TESTING:
      case UserTier.PAID:
      case UserTier.PRO_ZERO:
        return <LabelPro />;

      default:
        return '';
    }
  }, [appUser]);

  return (
    <CSSTransition
      in={display}
      timeout={ANIMATION_TIMEOUT}
      classNames={isMobile ? 'nj-animate-vertical-appearance' : ''}
      unmountOnExit
      appear={display}
      nodeRef={containerRef}
    >
      <div ref={containerRef} className={styles.root}>
        <div className={styles.content}>
          <XCircle
            size={SVG_SIZE}
            weight="fill"
            className={styles.close}
            onClick={handleClick}
          />
          <div className={styles.titleContainer}>
            <h4 className={styles.title}>{title}</h4>
            {(isOpenTier || isProTrialTier) && (
              <span className={styles.tag}>Limited time</span>
            )}
          </div>
          <p className={styles.description}>{description}</p>

          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

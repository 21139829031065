import { useContext, useEffect, useRef } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import {
  useQueryParams,
  useSubmitUserInput,
  useSession,
  useConversations,
} from 'src/hooks';
import { toast } from 'react-toastify';
import { DEFAULT_CHAT_ID } from 'src/constants';
import { sendGTMEvent } from 'src/utils';
import { BannerType, GTMEvent } from 'src/types';
import { useBanner } from 'src/hooks/useBanner';

/**
 * useChatPageQueryParams handles all query parameters on the Main chat page.
 */
export const useChatPageQueryParams = () => {
  const {
    searchParams: { session_id, query },
    removeSearchParam,
  } = useQueryParams();
  const { onSubmitUserInput } = useSubmitUserInput();
  const { removeBanners } = useBanner();
  const { appUser } = useSession();

  const { isConversationsLoading, isConversationsFetching, conversations } =
    useConversations();

  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);

  const queryCounter = useRef<number>(0);

  useEffect(() => {
    if (session_id) {
      //TODO(olha): WIP call checking endpoint
      toast('Welcome to Ninja Pro');
      removeBanners([
        BannerType.INSUFFICIENT_CREDITS,
        BannerType.LOW_CREDITS,
        BannerType.LOW_TASKS,
      ]);
      sendGTMEvent(GTMEvent.SUCCESSFUL_NEW_PRO_SUBSCRIPTION);
      removeSearchParam('session_id');
      //TODO(olha): WIP error handling toast('Session ID is not valid.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_id]);

  const handleSentQuery = (query: string) => {
    if (queryCounter.current === 0) {
      // TODO(olha): we need this workaround until we fix multi-rerender issue
      queryCounter.current += 1;

      const decodedQuery = decodeURIComponent(query);

      setThreadInputBoxValue(decodedQuery);
      onSubmitUserInput(decodedQuery, {
        conversation_id: DEFAULT_CHAT_ID,
      });
    }
  };

  useEffect(() => {
    if (
      !appUser.user_id ||
      isConversationsLoading ||
      isConversationsFetching ||
      !conversations
    ) {
      return;
    }

    if (query) {
      handleSentQuery(query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query,
    appUser.user_id,
    isConversationsLoading,
    isConversationsFetching,
    conversations,
  ]);
};

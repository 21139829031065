import { Info, Warning } from '@phosphor-icons/react';
import { useBreakpoint, useSession } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { SVG_SIZE_L } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

export const InsufficientCreditsBanner = () => {
  const { appUser, isProTrialTier, isEnterpriseTier, isProTier } = useSession();
  const { isTabletAndUp, isMobileOrTablet } = useBreakpoint();
  const navigate = useNavigate();
  const { onChangeIsVisibleUpdatePlanModal, onToggleVisibleContactNinjaModal } =
    useContext(SessionContext);

  const handleRenewEarlyClick = () => {
    onChangeIsVisibleUpdatePlanModal(true);
  };

  const handleUpgradeClick = () => {
    navigate(AppRoutes.PAYMENT);
  };

  const handleContactNinjaClick = () => {
    onToggleVisibleContactNinjaModal(true);
  };

  return (
    <div className="nj-common-banner">
      <div className="nj-common-banner--left-side-wrapper">
        {isEnterpriseTier ? (
          <Info size={SVG_SIZE_L} weight="fill" />
        ) : (
          <Warning size={SVG_SIZE_L} weight="fill" color="#D74E45" />
        )}
        {isProTrialTier ? (
          <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
            <h5 className="nj-common-banner--title">
              You’ve used up all your credits for external models
            </h5>
          </div>
        ) : (
          <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
            {isEnterpriseTier ? (
              <h5 className="nj-common-banner--title">
                Insufficient credits for external models
              </h5>
            ) : (
              <>
                <h5 className="nj-common-banner--title">
                  {isTabletAndUp
                    ? 'Insufficient credits'
                    : 'Insufficient funds'}
                </h5>
                {!isMobileOrTablet && (
                  <p className="nj-common-banner--description ellipsis">
                    Renew your subscription early to continue access to Pro
                    features.
                  </p>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="nj-common-banner--right-side-wrapper">
        {isEnterpriseTier && (
          <button
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleContactNinjaClick}
          >
            Contact Ninja
          </button>
        )}
        {isProTrialTier && (
          <button
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleUpgradeClick}
          >
            Upgrade
          </button>
        )}
        {isProTier && (
          <button
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleRenewEarlyClick}
            disabled={!!appUser?.stripe_info?.renew_early_pending_subscription}
          >
            Renew early
          </button>
        )}
      </div>
    </div>
  );
};

import { useSession } from 'src/hooks';
import { Tooltip } from './Tooltip';
import { GuestAuthLinks } from 'src/pages/AuthPages/components/GuestAuthLinks';
import { UpgradeLearnMoreButtons } from 'src/pages/AuthPages/components/UpgradeLearnMoreButtons';

export const PromoTooltip = () => {
  const {
    isProTier,
    isFreeTier,
    isOpenTier,
    isProTrialTier,
    isEnterpriseTier,
  } = useSession();

  if (isProTier || isProTrialTier || isEnterpriseTier) {
    return null;
  }

  return (
    <Tooltip>
      {isOpenTier && <GuestAuthLinks dataGTM="Toast-signup" />}
      {isFreeTier && <UpgradeLearnMoreButtons dataGTM="Toast-upgrade" />}
    </Tooltip>
  );
};

import { FormProvider } from 'src/components/FormProvider';
import { useForm } from 'react-hook-form';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { Icon } from 'src/components/Icon';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { Checkbox } from 'src/components/Checkbox';
import { useLazyGetUserByIdQuery } from 'src/store/services';
import { useSession, useUserData } from 'src/hooks';
import type { ExternModelOption } from 'src/types/models/ExternModelOption';
import { LabelInsufficientCredits } from 'src/components/LabelInsufficientCredits';
import { LabelPro } from 'src/components/LabelPro';
import './ExternalModelsSection.scss';
import { convertToValueWithoutDots, getExternalModelData } from 'src/utils';
import log from 'src/utils/logger';
import { UpsellMessage } from 'src/components/UpsellMessage';
import {
  externalModelProvidersSet,
  newExternalModelsList,
  imageGeneratorsModelsSet,
  newImageGeneratorModels,
  ImageGeneratorsModelProvider,
  AvailableImageGenerationModels,
  ImageGenerationModels,
} from 'src/constants';
import { FormSwitch } from 'src/components/FormSwitch';
import { LabelNew } from 'src/components/LabelNew';
import classNames from 'classnames';
import type { shared__models__user_settings__ImageGenSettingsOption } from 'src/types/models/shared__models__user_settings__ImageGenSettingsOption';
import { ChatGPTLogo } from 'src/images/logos/chat-gpt';
import { AWSLogo } from 'src/images/logos/aws-logo';
import type { ExternalModelSettings } from 'src/types/models/ExternalModelSettings';

const SVG_SIZE = 20;

// TODO: After completing the current design changes this component need to be broken into small ones
export const ExternalModelsSection = () => {
  const {
    appUser,
    isFreeTier,
    isProTier,
    isOpenTier,
    isProTrialTier,
    isEnterpriseTier,
  } = useSession();
  const [trigger] = useLazyGetUserByIdQuery();
  const { updateUserSettings } = useUserData();

  const methods = useForm({
    defaultValues: async () => {
      const result = await trigger(appUser.user_id, true);
      const { data } = result;

      const isAllSelected = (dataToCheck: ExternModelOption[]) => {
        const selected = dataToCheck.filter(
          (item) => item.enabled && item.is_capable,
        ).length;

        if (dataToCheck.length === selected) {
          return true;
        }
        return false;
      };

      const allExternalModelsBE = [
        ...(data?.settings?.external_models?.amazon_bedrock || []),
        ...(data?.settings?.external_models?.openai || []),
        ...(data?.settings?.external_models?.google || []),
      ];

      return Object.fromEntries([
        ...allExternalModelsBE.map((item) => [
          convertToValueWithoutDots(item.model_name),
          item.enabled || false,
        ]),
        [
          'amazon_bedrock',
          isAllSelected(data?.settings?.external_models?.amazon_bedrock || []),
        ],
        [
          'openai',
          isAllSelected(data?.settings?.external_models?.openai || []),
        ],
        [
          'google',
          isAllSelected(data?.settings?.external_models?.google || []),
        ],
      ]);
    },
  });

  const { reset, getValues, setValue } = methods;

  const methodsImageGen = useForm({
    defaultValues: async () => {
      const result = await trigger(appUser.user_id, true);
      const { data } = result;

      const models = (data?.settings?.image_gen_settings?.models || []).filter(
        (item) =>
          !AvailableImageGenerationModels.includes(
            item.model as ImageGenerationModels,
          ),
      );

      return models.reduce(
        (acc, current) => {
          acc[convertToValueWithoutDots(current.model)] = current.enabled;
          return acc;
        },
        {} as Record<string, boolean | undefined>,
      );
    },
  });

  const { reset: resetImages, getValues: getValuesImageGen } = methodsImageGen;

  const modelsList = Object.values(getValues()).every((item) => !item);
  const imageGenList = Object.values(getValuesImageGen()).every(
    (item) => !item,
  );
  const isClearDisabled = modelsList && imageGenList;

  const handleExternalModelValueChange = async (
    checked: boolean,
    groupName: keyof ExternalModelSettings,
    modelName: string,
  ) => {
    const updatedModelList = (
      appUser?.settings?.external_models
        ? ((appUser?.settings?.external_models as ExternalModelSettings)[
            groupName
          ] as ExternModelOption[])
        : []
    ).map((item) => {
      if (item?.model_name === modelName) {
        return {
          ...item,
          enabled: checked,
        };
      }
      return item;
    });
    const external_models = {
      external_models: {
        ...(appUser?.settings?.external_models || {}),
        [groupName]: updatedModelList,
      },
    };

    const availableFields = updatedModelList.filter((item) => item.is_capable);

    const isAllModelsSelected = availableFields.every((item) => item.enabled);

    const isAllModelsUnselected = availableFields.every(
      (item) => !item.enabled,
    );

    if (isAllModelsSelected || isAllModelsUnselected) {
      setValue(groupName, checked);
    }

    if (!isAllModelsSelected && !checked) {
      setValue(groupName, false);
    }

    try {
      await updateUserSettings(external_models, 'Preferred model saved');
    } catch (e) {
      log.error(e);
    }
  };

  const handleClearAllModels = async () => {
    const makeAllValuesDisabled = (data?: ExternModelOption[]) => {
      return (data || []).map((item) => ({ ...item, enabled: false }));
    };

    const updatedModelList = (
      appUser?.settings?.image_gen_settings?.models || []
    ).map((item) => {
      if (
        !AvailableImageGenerationModels.includes(
          item.model as ImageGenerationModels,
        )
      ) {
        return { ...item, enabled: false };
      }
      return item;
    });

    const settings = {
      external_models: {
        ...(appUser?.settings?.external_models || {}),
        amazon_bedrock: makeAllValuesDisabled(
          appUser?.settings?.external_models?.amazon_bedrock,
        ),
        openai: makeAllValuesDisabled(
          appUser?.settings?.external_models?.openai,
        ),
        google: makeAllValuesDisabled(
          appUser?.settings?.external_models?.google,
        ),
      },
      image_gen_settings: {
        ...appUser?.settings?.image_gen_settings,
        models: updatedModelList,
      },
    };

    try {
      await updateUserSettings(settings);

      // reset external models
      const allValues = getValues();
      const resetValues = Object.fromEntries(
        Object.keys(allValues).map((key) => [key, false]),
      );
      reset(resetValues);

      // reset external image models
      const allImageValues = getValuesImageGen();
      const resetImageValues = Object.fromEntries(
        Object.keys(allImageValues).map((key) => [key, false]),
      );
      resetImages(resetImageValues);
    } catch (e) {
      log.error(e);
    }
  };

  const handleSelectAllClick = async (
    checked: boolean,
    groupName: keyof ExternalModelSettings,
  ) => {
    let fieldsToChange = [] as string[];
    const makeAllValuesSelected = (data?: ExternModelOption[]) => {
      return (data || []).map((item) => {
        if (item?.is_capable) {
          fieldsToChange = [...fieldsToChange, item.model_name];
          return { ...item, enabled: checked };
        }
        return item;
      });
    };

    const external_models = {
      external_models: {
        ...(appUser?.settings?.external_models || {}),
        [groupName]: makeAllValuesSelected(
          appUser?.settings?.external_models
            ? (appUser?.settings?.external_models[
                groupName
              ] as ExternModelOption[])
            : [],
        ),
      },
    };

    try {
      await updateUserSettings(external_models);
      fieldsToChange.forEach((item) => {
        setValue(convertToValueWithoutDots(item), checked);
      });
    } catch (e) {
      log.error(e);
    }
  };

  const bedrockModels = (
    appUser?.settings?.image_gen_settings?.models || []
  ).filter(
    (item) => item.model.split('/')[0] === ImageGeneratorsModelProvider.BEDROCK,
  );

  const selectedBedrockModels = bedrockModels.filter(
    (item) => !!item.enabled,
  ).length;

  const openaiModels = (
    appUser?.settings?.image_gen_settings?.models || []
  ).filter(
    (item) => item.model.split('/')[0] === ImageGeneratorsModelProvider.OPENAI,
  );

  const selectedOpenaiModels = openaiModels.filter(
    (item) => !!item.enabled,
  ).length;

  const handleValueChange = async (checked: boolean, modelName: string) => {
    const updatedModelList = (
      appUser?.settings?.image_gen_settings?.models || []
    ).map((item) => {
      if (item?.model === modelName) {
        return {
          ...item,
          enabled: checked,
        };
      }
      return item;
    });

    const image_gen_settings = {
      image_gen_settings: {
        ...appUser?.settings?.image_gen_settings,
        models: updatedModelList,
      },
    };

    try {
      await updateUserSettings(image_gen_settings, 'Preferred model saved');
    } catch (e) {
      log.error(e);
    }
  };

  const renderModelsCheckboxes = (
    modelsList: Array<shared__models__user_settings__ImageGenSettingsOption>,
  ) =>
    modelsList.map(({ model, is_capable }, index) => {
      const currentModelObj = imageGeneratorsModelsSet[model];
      const LogoIcon = currentModelObj.icon;

      return (
        <div
          key={`${model}_${index}`}
          className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper"
        >
          <Checkbox
            label={
              <div className="nj-section--field-title-wrapper">
                <p className="nj-section--field-title">
                  <span>{currentModelObj.display_name || model}</span>
                  {currentModelObj.icon_name &&
                    !currentModelObj.hide_icon_in_settings &&
                    (LogoIcon ? (
                      <LogoIcon size={SVG_SIZE} />
                    ) : (
                      <Icon type={currentModelObj.icon_name} size={SVG_SIZE} />
                    ))}
                </p>
                {(isProTier || isProTrialTier || isEnterpriseTier) &&
                  !is_capable && <LabelInsufficientCredits />}
                {isFreeTier && !is_capable && <LabelPro />}
                {!isOpenTier &&
                  is_capable &&
                  newImageGeneratorModels.includes(model) && <LabelNew />}
              </div>
            }
            name={convertToValueWithoutDots(model)}
            onChangeHandler={(checked: boolean) => {
              handleValueChange(checked, model);
            }}
            disabled={!is_capable}
          />
        </div>
      );
    });

  const rightSideComponentAWS = (
    <span className="nj-accordion--label-selected-items">
      {selectedBedrockModels} selected
    </span>
  );

  const rightSideComponentOpenAI = (
    <span className="nj-accordion--label-selected-items">
      {selectedOpenaiModels} selected
    </span>
  );

  const { amazon_bedrock, openai } = externalModelProvidersSet;

  const contentAWS = (
    <div className="nj-accordion-external-models-content-wrapper">
      <div
        className={classNames(
          'nj-accordion-external-models-checkboxes-wrapper',
          {
            isAllDisabled: !appUser?.settings?.external_models?.is_capable,
          },
        )}
      >
        {renderModelsCheckboxes(bedrockModels)}
      </div>
      <a
        href={amazon_bedrock.url}
        target="_blank"
        rel="noreferrer"
        className="nj-accordion-external-models-learn-more"
      >
        <>
          <span>Learn more</span>
          <ArrowSquareOut size={SVG_SIZE} />
        </>
      </a>
    </div>
  );

  const contentOpenAI = (
    <div className="nj-accordion-external-models-content-wrapper">
      <div
        className={classNames(
          'nj-accordion-external-models-checkboxes-wrapper',
          {
            isAllDisabled: !appUser?.settings?.external_models?.is_capable,
          },
        )}
      >
        {renderModelsCheckboxes(openaiModels)}
      </div>
      <a
        href={openai.url}
        target="_blank"
        rel="noreferrer"
        className="nj-accordion-external-models-learn-more"
      >
        <>
          <span>Learn more</span>
          <ArrowSquareOut size={SVG_SIZE} />
        </>
      </a>
    </div>
  );

  const headerAWS = (
    <div className="nj-accordion--label-content-wrapper">
      <AWSLogo size={SVG_SIZE} />
      <span className="nj-accordion--label-content-text">
        Amazon Bedrock (2)
      </span>
    </div>
  );

  const headerOpenAI = (
    <div className="nj-accordion--label-content-wrapper">
      <ChatGPTLogo size={SVG_SIZE} />
      <span className="nj-accordion--label-content-text">Open AI (2)</span>
    </div>
  );

  return (
    <div className="nj-section--main-container with-padding">
      {!isProTier && !isEnterpriseTier && (
        <UpsellMessage
          description="Get access to 24+ premium Amazon Bedrock, OpenAI & Google Gemini models"
          dataGTMNinjaTier="External-model-upgrade"
          dataGTMOpenTier="External-model-signup"
        />
      )}

      {/* Image generation  */}
      <FormProvider methods={methodsImageGen}>
        <div className="nj-external-models-form-wrapper">
          <h5 className="nj-section--main-container-title">
            Image generation models
          </h5>
          <h6 className="nj-section--main-container-subtitle">
            Your default external image model preference
          </h6>
          <div className="nj-accordion nj-accordion-external-models">
            <CollapsedItem
              key={1}
              header={headerAWS}
              title={'this is the title'}
              content={contentAWS}
              isExpanded={false}
              rightSideComponent={rightSideComponentAWS}
            />

            <CollapsedItem
              key={2}
              header={headerOpenAI}
              title={'this is the title'}
              content={contentOpenAI}
              isExpanded={false}
              rightSideComponent={rightSideComponentOpenAI}
            />
          </div>
        </div>
      </FormProvider>

      {/* External models  */}
      <FormProvider methods={methods}>
        <div className="nj-external-models-form-wrapper">
          <h5 className="nj-section--main-container-title">
            Enable external model(s) for Researcher, Advisor and Coder results
          </h5>
          <h6 className="nj-section--main-container-subtitle">
            Your default external model preference
          </h6>
          <div className="nj-accordion nj-accordion-external-models">
            {Object.entries(externalModelProvidersSet).map(
              ([key, value], index) => {
                const modelsList =
                  (appUser?.settings?.external_models &&
                    (appUser?.settings?.external_models[
                      key as keyof ExternalModelSettings
                    ] as ExternModelOption[])) ||
                  [];

                const modelLength = modelsList.length;
                const LogoIcon = value.icon;

                const header = (
                  <div className="nj-accordion--label-content-wrapper">
                    {LogoIcon ? (
                      <LogoIcon />
                    ) : (
                      <Icon size={SVG_SIZE} type={value.icon_name} />
                    )}
                    <span className="nj-accordion--label-content-text">
                      {value.label} ({modelLength})
                    </span>
                  </div>
                );

                const selectedNumber = modelsList.filter(
                  (item) => !!item.enabled,
                ).length;

                const rightSideComponent = (
                  <span className="nj-accordion--label-selected-items">
                    {selectedNumber} selected
                  </span>
                );

                const content = (
                  <div className="nj-accordion-external-models-content-wrapper">
                    <div
                      className={classNames(
                        'nj-accordion-external-models-checkboxes-wrapper',
                        {
                          isAllDisabled:
                            !appUser?.settings?.external_models?.is_capable,
                        },
                      )}
                    >
                      <div className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper">
                        <div className="nj-section--field-title">
                          <span>Select all</span>
                        </div>

                        <FormSwitch
                          name={key}
                          onChangeHandler={(checked: boolean) =>
                            handleSelectAllClick(
                              checked,
                              key as keyof ExternalModelSettings,
                            )
                          }
                          isDisabled={
                            (modelsList || []).every(
                              (item) => !item.is_capable,
                            ) || !appUser?.settings?.external_models?.is_capable
                          }
                        />
                      </div>
                      {modelsList.map(
                        (
                          { model_name, is_capable }: ExternModelOption,
                          index: number,
                        ) => {
                          const model = getExternalModelData(model_name);
                          if (!model) {
                            return <></>;
                          }

                          const LogoIcon = model.icon;

                          return (
                            <div
                              key={`${model_name}_${index}`}
                              className="nj-section--field-wrapper nj-web-search-checkboxes-wrapper"
                            >
                              <Checkbox
                                label={
                                  <div className="nj-section--field-title-wrapper">
                                    <p className="nj-section--field-title">
                                      <span>{model.display_name}</span>
                                      {model.icon_name &&
                                        !model.hide_icon_in_settings &&
                                        (LogoIcon ? (
                                          <LogoIcon size={SVG_SIZE} />
                                        ) : (
                                          <Icon
                                            type={model.icon_name}
                                            size={SVG_SIZE}
                                          />
                                        ))}
                                    </p>
                                    {(isProTier ||
                                      isProTrialTier ||
                                      isEnterpriseTier) &&
                                      !is_capable && (
                                        <LabelInsufficientCredits />
                                      )}
                                    {isFreeTier && !is_capable && <LabelPro />}
                                    {!isOpenTier &&
                                      is_capable &&
                                      newExternalModelsList.includes(
                                        model_name,
                                      ) && <LabelNew />}
                                  </div>
                                }
                                name={convertToValueWithoutDots(model_name)}
                                onChangeHandler={(checked: boolean) => {
                                  handleExternalModelValueChange(
                                    checked,
                                    key as keyof ExternalModelSettings,
                                    model_name,
                                  );
                                }}
                                disabled={
                                  !is_capable ||
                                  !appUser?.settings?.external_models
                                    ?.is_capable
                                }
                              />
                            </div>
                          );
                        },
                      )}
                    </div>
                    <a
                      href={value.url}
                      target="_blank"
                      rel="noreferrer"
                      className="nj-accordion-external-models-learn-more"
                    >
                      <>
                        <span>Learn more</span>
                        <ArrowSquareOut size={SVG_SIZE} />
                      </>
                    </a>
                  </div>
                );

                return (
                  <CollapsedItem
                    key={key}
                    header={header}
                    title={value.label}
                    content={content}
                    isExpanded={false}
                    rightSideComponent={rightSideComponent}
                  />
                );
              },
            )}
          </div>
        </div>
      </FormProvider>

      <button
        className="nj-external-models-clear-button"
        type="button"
        disabled={isClearDisabled}
        onClick={handleClearAllModels}
      >
        Clear all
      </button>
    </div>
  );
};

import { Header } from 'src/pages/AuthPages/components/Header';
import atlasSmile from 'src/images/login/atlas_smile.png';
import {
  DEFAULT_PRIVACY_POLICY_LINK,
  DEFAULT_TERMS_OF_SERVICE_LINK,
} from 'src/constants/externalLinks';
import AuthContext from 'src/contexts/AuthContext';
import { useContext, useState } from 'react';
import { Envelope } from '@phosphor-icons/react';
import { SignupHeader } from 'src/pages/AuthPages/components/SignupHeader';
import { useForm, useWatch } from 'react-hook-form';
import { FormInput } from 'src/components/FormInput';
import { FormProvider } from 'src/components/FormProvider';
import { ServiceError } from '@aws-amplify/core/src/types/errors';
import { useLazyCheckEmailInfoQuery } from 'src/store/services';
import { useBreakpoint } from 'src/hooks';
import { Link, useNavigate } from 'react-router-dom';
import { sendGTMEvent } from 'src/utils';
import { isGoogleEmailProvider } from 'src/utils/user';
import { GTMEvent, AppRoutes, SSOProvider } from 'src/types';
import { SSOButton } from 'src/pages/AuthPages/components/SSOButton';
import { Helmet } from 'react-helmet-async';

enum SignupFields {
  EMAIL = 'email',
}

interface FormData {
  [SignupFields.EMAIL]: string;
}

// todo: needs to refactoring this component (duplicated buttons, functions)
export const SignupPage = () => {
  const { setAuthLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { isMobileOrTablet } = useBreakpoint();
  const [isPromptToSignupWithGoogle, setIsPromptToSignupWithGoogle] =
    useState(false);

  const [checkEmailInfo] = useLazyCheckEmailInfoQuery();

  const methods = useForm<FormData>({
    defaultValues: {
      [SignupFields.EMAIL]: '',
    },
  });

  const { handleSubmit, control } = methods;

  const fields = useWatch({
    control,
  });

  const encodedEmail = encodeURIComponent(fields[SignupFields.EMAIL] || '');

  const redirectToEmailSignUp = `/sign-up-with-email?email=${encodedEmail}`;

  const submitForm = async (formData: FormData) => {
    try {
      setErrorMessage('');
      const { data } = await checkEmailInfo(
        formData[SignupFields.EMAIL].toLowerCase(),
      );

      sendGTMEvent(GTMEvent.EMAIL_SIGNUP_CLICKS);

      if (isGoogleEmailProvider(data?.provider)) {
        setIsPromptToSignupWithGoogle(true);
      } else {
        navigate(redirectToEmailSignUp);
      }
    } catch (error) {
      setErrorMessage(error ? (error as ServiceError).message : 'error');
    }
  };

  const title = 'Account Signup | Ninja AI';
  const description =
    'Sign up for your free trial of Ninja Pro to enjoy boundless productivity with your own personal AI Assistant, along with access to 24+ premium AI models.';

  return (
    <div className="nj-auth-layout nj-beta">
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta content={description} name="description" data-rh="true" />
        <meta content={title} property="og:title" />
        <meta content={description} property="og:description" data-rh="true" />
        <meta content={title} property="twitter:title" data-rh="true" />
        <meta
          content={description}
          property="twitter:description"
          data-rh="true"
        />
      </Helmet>
      <Header rightSide={<SignupHeader />} />
      <main className="nj-auth-content">
        <h1 className="nj-auth-header-blue-gradient">Meet Ninja</h1>
        <h2 className="nj-auth-header-small">Your Autonomous AI Agent</h2>
        <h3 className="nj-auth-header-secondary">Set up your account</h3>
        {isPromptToSignupWithGoogle ? (
          <>
            <p className="nj-auth-header-greeting">
              Hi {fields[SignupFields.EMAIL]}
            </p>
            <p className="nj-auth-header-description">
              The easiest way for you to sign up is with Google. By doing so,
              you can connect your calendar, enabling Ninja to access your
              availability and schedule meetings.
            </p>
            <SSOButton
              ssoProvider={SSOProvider.GOOGLE}
              isSignUp={true}
              setAuthLoading={setAuthLoading}
            />
            <p className="nj-auth-signup-hint">
              Prefer to create an account with a password?
            </p>
            <Link className="nj-auth-signup-link" to={redirectToEmailSignUp}>
              Click here
            </Link>
          </>
        ) : (
          <>
            <div className="nj-auth-sign-in-up-buttons">
              <SSOButton
                ssoProvider={SSOProvider.GOOGLE}
                isSignUp={true}
                setAuthLoading={setAuthLoading}
              />
              <SSOButton
                ssoProvider={SSOProvider.MICROSOFT}
                isSignUp={true}
                setAuthLoading={setAuthLoading}
              />
              <SSOButton
                ssoProvider={SSOProvider.APPLE}
                isSignUp={true}
                setAuthLoading={setAuthLoading}
              />
              <SSOButton
                ssoProvider={SSOProvider.AMAZON}
                isSignUp={true}
                setAuthLoading={setAuthLoading}
              />
              <SSOButton
                ssoProvider={SSOProvider.LINKEDIN}
                isSignUp={true}
                setAuthLoading={setAuthLoading}
              />
              <SSOButton
                ssoProvider={SSOProvider.FACEBOOK}
                isSignUp={true}
                setAuthLoading={setAuthLoading}
              />
              <div className="nj-auth-separator-block">
                <div className="nj-auth-separator-block-line" />
                <span>OR</span>
                <div className="nj-auth-separator-block-line" />
              </div>
              <FormProvider<FormData> methods={methods}>
                <form
                  onSubmit={handleSubmit(submitForm)}
                  className="nj-auth-form no-margin"
                >
                  <FormInput
                    name={SignupFields.EMAIL}
                    type="email"
                    className="nj-auth-form--input"
                    placeholder="Enter Email"
                    required={true}
                    hasErrorBorder={errorMessage.length > 0}
                  />
                  <button className="nj-auth-sign-in-up-button" type="submit">
                    <div className="nj-button-content-wrapper">
                      <Envelope size={24} />
                      <span className="nj-button-contents">
                        Sign up with Email
                      </span>
                    </div>
                  </button>
                </form>
              </FormProvider>
            </div>
            <p className="nj-auth-agreement-label">
              By signing up, you agree to the
              <a
                href={DEFAULT_TERMS_OF_SERVICE_LINK}
                target="_blank"
                rel="noreferrer"
                className="nj-auth-terms-link"
              >
                &nbsp;Terms of Service&nbsp;
              </a>
              and have read and acknowledge our
              <a
                href={DEFAULT_PRIVACY_POLICY_LINK}
                target="_blank"
                rel="noreferrer"
                className="nj-auth-terms-link"
              >
                &nbsp;Privacy Policy
              </a>
            </p>
          </>
        )}

        <Link
          className="nj-auth-signup-link nj-auth-go-back-link"
          to={AppRoutes.HOME}
        >
          Go back to myninja.ai
        </Link>
      </main>
      {!isMobileOrTablet && (
        <img
          className="nj-auth-background-image"
          src={atlasSmile}
          alt="Ninja"
        />
      )}
    </div>
  );
};

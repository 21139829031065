import { useBreakpoint } from 'src/hooks';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'src/types';
import { Helmet } from 'react-helmet-async';

export const LoginHeader = () => {
  const { isMobile } = useBreakpoint();
  const title = 'Account Login | Ninja AI';
  const description =
    "Sign in to your Ninja AI account to access your AI assistant that's ready to save you time with research, writing, coding, and so much more.";
  return (
    <div className="nj-auth-header-right-side no-gap">
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta content={description} name="description" data-rh="true" />
        <meta content={title} property="og:title" />
        <meta content={description} property="og:description" data-rh="true" />
        <meta content={title} property="twitter:title" data-rh="true" />
        <meta
          content={description}
          property="twitter:description"
          data-rh="true"
        />
      </Helmet>
      {!isMobile && <span>Don't have an account? &nbsp;</span>}
      <Link to={AppRoutes.SIGN_UP} className="nj-button nj-auth-link">
        Sign up
      </Link>
    </div>
  );
};

import classNames from 'classnames';
import { PlanSectionType } from 'src/types';
import { UpgradeForm } from '../UpgradeForm';
import './PlanSection.scss';
import { FreeSection } from '../FreeSection';
import { ProSection } from '../ProSection';
import { useSession } from 'src/hooks';

interface PlanSectionProps {
  type: PlanSectionType;
  onOpenMobileCheckout: () => void;
}

export const PlanSection = ({
  type,
  onOpenMobileCheckout,
}: PlanSectionProps) => {
  const isProSection = type === 'pro-section';
  const { isFreeTier } = useSession();

  return (
    <div className={classNames('nj-plan-section', { active: isProSection })}>
      <h5 className="nj-plan-section--title-wrapper">
        <span className="nj-plan-section--title">
          Ninja {isProSection ? 'Pro' : 'Free'}
        </span>
        {isProSection && (
          <span className="nj-plan-section--title-label">Added to cart</span>
        )}
        {isFreeTier && !isProSection && (
          <span className="nj-plan-section--title-label-current">
            Current plan
          </span>
        )}
      </h5>

      <p className="nj-plan-section--description">
        {isProSection
          ? 'Full access to Ninja AI and external models for personal and commercial use'
          : 'For casual users with limited usage and no need for external AI models'}
      </p>

      {isProSection ? (
        <UpgradeForm onOpenMobileCheckout={onOpenMobileCheckout} />
      ) : (
        <div className="nj-plan-section--current-plan">
          <p className="nj-plan-section--current-plan-price">
            <span className="nj-plan-section--current-plan-title">
              $0&nbsp;
            </span>
            <span className="nj-plan-section--current-plan-subtitle">/mo</span>
          </p>
        </div>
      )}

      <div className="nj-plan-section--feature-list">
        {!isProSection && <FreeSection />}

        {isProSection && <ProSection />}
      </div>
    </div>
  );
};

import './UpgradeForm.scss';
import { useBreakpoint } from 'src/hooks';

type UpgradeFormProps = {
  onOpenMobileCheckout: () => void;
};

export const UpgradeForm = ({ onOpenMobileCheckout }: UpgradeFormProps) => {
  const { isMobile } = useBreakpoint();

  return (
    <div className="nj-upgrade-form">
      <div className="nj-plan-section--current-plan">
        <p className="nj-plan-section--current-plan-price">
          <span className="nj-plan-section--current-plan-title">$10&nbsp;</span>
          <span className="nj-plan-section--current-plan-subtitle">/mo</span>
        </p>
        <span className="nj-plan-section--current-plan-price-description">
          50% off for first month
        </span>
      </div>

      {isMobile && (
        <button
          className="nj-button nj-button--primary nj-upgrade-form--submit"
          onClick={onOpenMobileCheckout}
          id="Plan-card-upgrade"
          // TODO(olha): will be implement in the next PR
          // disabled={isLoading || !data}
        >
          Purchase
        </button>
      )}
    </div>
  );
};
